// React
import React from 'react';

// Props types
import PropTypes from 'prop-types';

// Styles
import './style.css';

// Components
import { RadioOption } from './radio-option';

export const RadioOptions = ({ options, name, disabled, isRequired, additionalStyles = '' }) => (
  <div className={`text-sm w-auto ${additionalStyles}`}>
    {options.map((option) => (
      <RadioOption key={option} option={option} name={name} disabled={disabled} isRequired={isRequired} />
    ))}
  </div>
);

RadioOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

RadioOptions.defaultProps = {
  disabled: false
};
