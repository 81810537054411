import { isKsaRegion } from '../../utils';
import * as PAYMENT_CONSTANTS from './constants';

const { hostname } = window.location;

const initialState = {
  envId: '',
  outletReference: '',
  tenantName: '',
  order: {
    action: '',
    amount: {
      currencyCode: '',
      value: 0
    },
    merchantAttributes: {
      redirectUrl: `https://${hostname}/#/products?showStatus=true`,
      skip3DS: true,
      paymentAttempts: '3'
    },
    emailAddress: ''
  }
};


const paymentRequestReducer = (state = initialState, action) => {

  const data = action.payload;

  switch (action.type) {
    case PAYMENT_CONSTANTS.UPDATE_ENV:
      return { ...state, envId: data };


    // eslint-disable-next-line no-case-declarations
    case PAYMENT_CONSTANTS.UPDATE_PAYMENT_REQUEST_DATA:
      const isKsaEnv = isKsaRegion();
      const [outletRef, merchant] = data.merchantAndOutlet.split('|');

      const order = {
        ...state.order,
        amount: { ...state.order.amount, currencyCode: data.currency },
        emailAddress: data.email,
        action: data.paymentAction,
        merchantAttributes: {
          ...state.order.merchantAttributes,
          skip3DS: data.skip3DS,
          // added dynamically properties 🔽
          ...((data.maskPaymentInfo && data.maskPaymentInfo === true)
            && { maskPaymentInfo: data.maskPaymentInfo }),

          ...((data.skipFraudCheck && data.skipFraudCheck === true)
            && { skipFraudCheck: data.skipFraudCheck }),

          ...((data.skipRiskRules && data.skipRiskRules !== 'None')
            && { skipRiskRules: data.skipRiskRules }),

          ...((data.passengerNameRecord)
            && { passengerNameRecord: data.passengerNameRecord })
        }
      }

      if(isKsaEnv) {
        const customAmount = Number.parseInt(data.customAmount)
        order.amount.value = order.amount.value === 0 && customAmount > 0 ? customAmount :  order.amount.value
        order.amount.currencyCode = 'SAR';
        order.type = 'SINGLE';

        if(['RECURRING', 'INSTALLMENT'].includes(data.paymentAction)) {
          let updatedMerchantAttributes = { ...order.merchantAttributes, paymentAttempts: 1 };
          order.action = 'PURCHASE';
          order.type = data.paymentAction;
          order.merchantAttributes = { ...updatedMerchantAttributes }
        }

        if(data.paymentAction === 'UNSCHEDULED') {
          if (['AUTH', 'PURCHASE'].includes(data.unscheduledType)) {
            order.action = data.unscheduledType;
          }
          order.type = data.paymentAction;
        }
        order.parentOrderReference = data.unscheduledType === 'SUBSEQUENT' ? data.parentOrderReference : undefined;
        if(data.isSavedCard) {
          order.savedCardOrderReference = data.savedCardOrderReference;
        } else {
          delete order.savedCardOrderReference;
        }

        switch (data.paymentAction) {
          case 'RECURRING': 
            order.frequency = data.recurringFrequency;
            order.channel = 'Ecom';
            order.recurringDetails = {
              recurringType: data.recurringOrderType,
              numberOfTenure: Number.parseInt(data.numberOfTenure)
            }
            break;
          case 'INSTALLMENT':
            order.frequency = data.recurringFrequency;
            order.installmentDetails = {
              numberOfTenure: Number.parseInt(data.numberOfInstallmentTenure)
            }
            break;
          case 'VERIFY':
            order.amount.value = 0;
        }
      }

      return {
        ...state,
        // here will add merchantId || (apiKey || accessToken)
        // added dynamically properties 
        ...((data.keyOrAccess) && { [data.keyOrAccess]: data?.keyOrAccessValue }),
        ...((!data.keyOrAccess) && { merchantId: merchant }),
        outletReference: outletRef,
        tenantName: data.tenant,
        order
      };

    case PAYMENT_CONSTANTS.UPDATE_TOTAL:
      return { ...state, order: { ...state.order, amount: { ...state.order.amount, value: data } } };

    case PAYMENT_CONSTANTS.RESET_PAYMENT_REQUEST_DATA:
      localStorage.removeItem('persist:root')
      return { ...initialState };
    default: {
      return state;
    }
  }
};

export default paymentRequestReducer;
