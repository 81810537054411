// React
import React, { useEffect, useState } from 'react'

// Axios instance
import authInstance from '../../../axios/authInstance'

// React toastify
import { toast } from 'react-toastify'
import { toastTheme } from '../../../utils/toastTheme'

// Components
import Users from '../../../components/admin/ManageUsers/Users/Users'
import Spinner from '../../../components/Spinner/Spinner';

export const ManageUsers = () => {

    const [users, setUsers] = useState({
        allUsers: [],
        loading: false,
        error: null
    })

    useEffect(() => {
        fetchUsers();
    }, [])

    const fetchUsers = () => {
        setUsers({ ...users, loading: true })
        authInstance.get('admin/auth/users')
            .then((response) => setUsers({ ...users, allUsers: response.data, loading: false }))
            .catch((err) => {
                setUsers({ ...users, error: err })
                toast.error('Something went wrong !!', toastTheme)
            })
    }


    if (users.loading) return <Spinner />

    return (
        <div className="h-screen px-6 bg-slate-50
         lg:h-[calc(100vh-theme('spacing.16'))] w-full grid grid-cols-12 gap-4 grid-rows-12">
            <div className='col-start-1 col-span-8 row-start-2 md:row-start-2 lg:row-start-2
             row-span-1 inline-block h-fit'>
                <h1 className='text-gray-800 font-montserrat text-base lg:text-3xl md:text-2xl
                     font-semibold uppercase pt-0 md:pt-2 lg:pt-2'>
                    Manage Users </h1>
            </div>
            <div className='flex items-center col-start-3 col-span-8
             row-start-4 md:row-start-3 lg:row-start-3'>

            </div>
            <div className='col-start-3 col-span-8 row-start-4 row-span-6 h-fit md:h-72 lg:h-96 bg-slate-50'>
                <Users refetchUsers={fetchUsers} users={users.allUsers} />
            </div>
        </div>
    );
}