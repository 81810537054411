import {useParams} from 'react-router-dom';
import React, {useState} from 'react';
import {isKsaRegion} from '../../utils';
import authInstance from '../../axios/authInstance';
import {toast} from 'react-toastify';
import {toastTheme} from '../../utils/toastTheme';
import ConfirmationDialogue from '../confirmation-dialogue/ConfirmationDialogue';

const SettlementButton = () => {
    const {tenant, envId, tenId} = useParams();
    const [updateSettlementTimeAction, setSettlementTimeAction] = useState({
        loading: false,
        error: null
    });
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleUpdateSettlementTime = async () => {
        setSettlementTimeAction({...updateSettlementTimeAction, loading: true});
        try {
            const merchants = await authInstance.get(`admin/config/tenants/${tenId}/merchants`);
            await authInstance.put('settlement', {
                envId,
                tenantName: tenant,
                outletReference: merchants?.data[0]?.outlets[0]?.reference
            });
            setSettlementTimeAction({...updateSettlementTimeAction, loading: false});
            toast.success('Settlement time has been updated', toastTheme);
        } catch (err) {
            const errorMessage = err.response?.data?.message ?? 'Something went wrong';
            toast.error(errorMessage, toastTheme);
            setSettlementTimeAction({...updateSettlementTimeAction, loading: false, error: err});
        } finally {
            setShowConfirmation(false);
        }
    };

    return (
        <>
            {isKsaRegion() && (
                <>
                    <button
                        onClick={() => setShowConfirmation(true)}
                        className="border-2 border-rose-500 text-red-500 text-xs md:text-base lg:text-base
                hover:bg-rose-500 hover:text-white px-8 py-2 rounded-md font-medium">
                        Update Settlement Time
                    </button>
                    <ConfirmationDialogue
                        showModal={showConfirmation}
                        close={() => setShowConfirmation(false)}
                        onConfirm={handleUpdateSettlementTime}
                        isLoading={updateSettlementTimeAction.loading}
                        title="Are you sure?"
                        content="Do you want to update the settlement time?"
                        confirmButtonText="Yes"
                        cancelButtonText="Cancel"
                    />
                </>
            )}
        </>
    );
}

export default SettlementButton;
