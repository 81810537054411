// React
import React from 'react'

// Components
import ModalSvg from './ModalSvg';

const Modal = ({ closeModal, data, orderRef }) => {

    const getModalText = (status) => {
        const successStates = ['AUTHORISED', 'PURCHASED', 'CAPTURED'];

        if (successStates.includes(status)) {
            return 'Your payment has been successfully processed.'
        } else if (status === 'FAILED') {
            return 'Your payment could not be processed successfully.'
        }
        // the rest else if blocks will used in future cases 
    }

    return (
        <div className="fixed top-0 left-0 flex items-center
             justify-center w-full h-full bg-gray-900 bg-opacity-50 z-10">
            <div className="z-10 flex max-w-lg flex-col items-center
                 rounded-3xl px-8 py-10 text-gray-800 shadow-lg bg-slate-900 w-10/12 h-auto gap-7">
                <ModalSvg status={data} />
                <p className="text-center text-base md:text-lg lg:text-xl font-medium text-gray-200">
                    Order Status : {data}
                </p>

                <p className="text-center w-2/3 text-base md:text-lg lg:text-xl font-medium text-gray-200">
                    Order Reference :
                    <p className='select-all mb-4 text-gray-400 text-xs md:text-base lg:text-lg'>
                        {orderRef}
                    </p>
                </p>
                <hr className='w-2/3 d-inline p-0 m-0 text-ni-red' />

                <p className="text-center font-bold text-sm md:text-lg lg:text-xl text-gray-200">
                    {getModalText(data)}
                </p>

                <button onClick={() => closeModal()}
                    className="text-xs md:text-base lg:text-base
                     rounded-md bg-red-500 px-6 py-4 font-medium text-white">
                    Close
                </button>
            </div>
        </div >
    );
}

export default Modal;
