// Redux
import { useSelector } from 'react-redux';

// React 
import * as React from 'react';

// Router dom
import { Link, useParams } from 'react-router-dom';

// Components
import Breadcrumbs from '../../../components/admin/MerchantConfig/BreadCrumbs/BreadCrumbs';

const Layout = ({ children }) => {
    const { environment, tenant } = useParams();
    const { allEnvs } = useSelector((state) => state.environmentsReducer);


    return (
        <div className="h-screen px-6 bg-slate-50
         lg:h-[calc(100vh-theme('spacing.16'))] w-full grid grid-cols-12 gap-4 grid-rows-12">
            <div className='col-start-1 col-span-8 row-start-3 md:row-start-2 lg:row-start-2
             row-span-1 inline-block h-fit'>
                <h1 className='text-gray-800 font-montserrat text-base lg:text-3xl md:text-2xl
                     font-semibold uppercase pt-0 md:pt-2 lg:pt-2 mb-2'>
                    Merchant Configurations </h1>
            </div>
            <div className='flex items-center col-start-3 col-span-8
             row-start-4 md:row-start-3 lg:row-start-3'>
                {/* if there is no tenant we will not show the breadcrumbs */}
                {!tenant ?
                    <div className='flex items-center gap-4'>
                        {allEnvs?.map((env) => (
                            <Link key={env?.id} to={`/merchantConfigurations/${env?.name}/${env?.id}`}
                                className={`relative inline-block no-underline text-black
                            ${environment === env?.name ? 'font-semibold' : ''}`}>
                                <span className={`rounded-s-full rounded-e-full	
                                 absolute inset-x-0 bottom-0 h-1 bg-red-500 transition-all transform
                            ${environment === env?.name ? 'scale-x-100' : 'scale-x-0'}`}></span>
                                {env?.name}
                            </Link>
                        ))}
                    </div>
                    :
                    <Breadcrumbs show={() => setShowConfirmModal(true)} />
                }
            </div>
            <div className='col-start-3 col-span-8 row-start-5 row-span-6 h-fit md:h-72 lg:h-96 bg-slate-50'>
                {children}
            </div>
        </div>
    )
}

export default Layout