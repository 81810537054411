import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// Router dom
import { Navigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const Auth = ({ children }) => {
  const { isAuth } = useSelector((state) => state.authReducer);

  if (isAuth) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default Auth;
