// React
import React from 'react';

// Props type
import PropTypes from 'prop-types';

// Form context
import { useFormContext } from 'react-hook-form';

// style
import './style.css';

export const RadioOption = ({ option, name, disabled, checked, isRequired }) => {
  const { register } = useFormContext();

  return (
    <div className='flex gap-2 w-36 mb-2'>
      <input
        {...register(name, {
          required: { value: isRequired, message: 'Please select an option' }
        })}
        className="text-gray-100 checked:bg-ni-red"
        value={option}
        key={option}
        type="radio"
        id={option}
        name={name}
        disabled={disabled}
        checked={checked}
        style={{
          appearance: 'none',
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          border: '2px solid #d42f49'
        }}
      />
      <label className="text-gray-100 font-normal block" htmlFor={option}>
        {option}
      </label>
    </div>
  );
};

RadioOption.propTypes = {
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool
};

RadioOption.defaultProps = {
  disabled: false,
  checked: undefined
};
