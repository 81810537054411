// React
import React from 'react';

export const About = () => {
    return (
        <div className="flex h-[calc(100vh-theme('spacing.16'))] w-full">
            <div className="flex flex-col md:w-full lg:w-full bg-home">
                <div
                    className="my-auto flex flex-col px-6 pt-8 sm:px-24 md:justify-start md:px-8 md:pt-0 lg:px-12">
                    <p className="text-6xl text-ni-blue font-bold">Ngenius Online</p>
                    <p className="text-6xl text-ni-blue font-bold">Demo Store</p>
                    <p className="text-2xl text-ni-blue-light mt-2">
                        The Demo E-commerce Store is to replace the old demo store,
                        which allows users to choose the environments/tenants, create orders,
                        and make transactions quickly while testing
                        on both Sandbox and My Old Shoes Production merchant.</p>
                </div>

            </div>
        </div>);
}