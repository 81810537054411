// Router dom
import { useNavigate, useLocation, Link } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// React
import React, { useEffect, useState } from 'react';

// Action creators
import { LogoutUser } from '../../redux/authReducer/actions';

import networkLogo from '../../images/network-logo.png'


export const Header = ({ toggle }) => {
  const { isAuth, accessToken, isAdmin } = useSelector((state) => state.authReducer);
  const { allEnvs } = useSelector((state) => state.environmentsReducer);

  const [firstEnv, setFirstEnv] = useState(allEnvs[0]);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [activeLink, setActiveLink] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;

  useEffect(() => {
    setFirstEnv(allEnvs[0]);
  }, [allEnvs])

  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  const handleLogout = () => {
    dispatch(LogoutUser(accessToken));
    setMobileMenuOpen(false)
    navigate('/login');
  };

  return (
    <nav className="bg-gray-800 border-ni-red border-b-4 fixed md:relative lg:relative z-10 w-full h-16">
      <div className="mx-auto max-w-full px-2">
        <div className="relative flex px-6 h-16 items-center justify-between">

          {/* Mobile view toggle button */}
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              data-testid='toggle'
              type="button"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
              }}
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400
                hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5" />
              <svg
                className={`${mobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#e5e7eb"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <svg
                className={`${mobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#e5e7eb"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* configs icon  */}
          {window.location.href.includes('products') && <div className='flex items-center justify-center'>
            <button onClick={() => toggle()}
              data-testid='configToggle'
              className="absolute p-0 inset-y-0 right-0 flex justify-center items-center
                         sm:hidden w-9 m-auto aspect-square
                         rounded-md text-gray-400  
                         hover:bg-gray-700 hover:text-white focus:outline-none
                         focus:ring-2 focus:ring-inset focus:ring-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="24"
                height="24" viewBox="0 0 24 24" fill="none" stroke="#e5e7eb"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="4" y1="21" x2="4" y2="14">
                </line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line>
                <line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line>
                <line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line>
              </svg>
            </button>
          </div>
          }

          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">

            {/* Logo and Name */}
            <div className="flex flex-shrink-0 items-center">
              <img
                className="h-8 w-auto md:w-5 md:h-5 lg:w-auto lg:h-8"
                src={networkLogo}
                alt="Ngenius Logo"
              />
              <div className="text-white font-medium ">
                <p className='inline text-base lg:text-lg md:text-sm'>Ngenius Demo Store</p>
              </div>
            </div>

            {/* Nav left end buttons */}
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <Link
                  to={'/products'}
                  className={`flex items-center text-gray-300 md:text-xs sm:text-xs lg:text-base hover:bg-gray-700
                   hover:text-white rounded-md px-2 py-2 text-sm 
                   font-medium no-underline ${(activeLink === '/products' || activeLink === '/login')
                    && 'bg-gray-700 text-white'}`}
                  aria-current="page">
                  Home
                </Link>

                {/* Show only for the admin users */}
                {isAdmin &&
                  <Link to={`/merchantConfigurations/${firstEnv?.name}/${firstEnv?.id}`}
                    className={`flex items-center text-gray-300 md:text-xs sm:text-xs lg:text-base hover:bg-gray-700
                  hover:text-white rounded-md px-2 py-2 text-sm font-medium no-underline
                   ${activeLink?.startsWith('/merchantConfigurations') && 'bg-gray-700 text-white'}`}
                    aria-current="page">
                    Merchant Configurations
                  </Link>
                }

                {isAdmin && <Link
                  to={'/manageUsers'}
                  className={`flex items-center text-gray-300 md:text-xs sm:text-xs lg:text-base hover:bg-gray-700
                 hover:text-white rounded-md px-2 py-2 text-sm
                 font-medium no-underline ${activeLink === '/manageUsers' && 'bg-gray-700 text-white'}`}
                  aria-current="page">
                  Manage Users
                </Link>
                }

                <Link
                  to={'/about'}
                  className={`flex items-center text-gray-300 md:text-xs sm:text-xs lg:text-base hover:bg-gray-700
                   hover:text-white rounded-md px-2 py-2 text-sm
                   font-medium no-underline ${activeLink === '/about' && 'bg-gray-700 text-white'}`}
                  aria-current="page"
                >About
                </Link>
              </div>
            </div>
          </div>

          {/* Navbar right end buttons */}
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="flex space-x-4">
              {isAuth && (
                <button
                  onClick={handleLogout}
                  className="text-gray-950 bg-ni-gray hover:text-gray-50
                   hover:bg-ni-red rounded-md px-4 py-2 text-sm
                   font-medium no-underline sm:block hidden"
                >
                  Sign out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Navbar buttons for mobile view */}
      {mobileMenuOpen && (
        <div className="sm:" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2 bg-gray-800">
            <Link
              onClick={() => setMobileMenuOpen(false)}
              to={'/products'}
              className={`bg-gray-900 text-white block rounded-md px-3
               py-2 text-base font-medium no-underline ${activeLink === '/products' && 'bg-gray-700 text-white'}`}
              aria-current="page"
            >Home
            </Link>
            {isAdmin &&
              <>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  to={`/merchantConfigurations/${firstEnv?.name}/${firstEnv?.id}`}
                  className={`bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium no-underline
                   ${activeLink === '/merchantConfigurations/DEV' && 'bg-gray-700 text-white'}`}
                  aria-current="page"
                >Merchant Configurations
                </Link>

                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  to={'/manageUsers'}
                  className={`bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium
                   no-underline ${activeLink === '/manageUsers' && 'bg-gray-700 text-white'}`}
                  aria-current="page"
                >Manage Users
                </Link>
              </>
            }

            <Link
              onClick={() => setMobileMenuOpen(false)}
              to={'/about'}
              className={`bg-gray-900 text-white block rounded-md px-3 py-2 text-base
               font-medium no-underline ${activeLink === '/about' && 'bg-gray-700 text-white'}`}
              aria-current="page"
            >About
            </Link>

            <div className="flex space-x-4">
              {isAuth && (
                <Link to={'/'}
                  onClick={handleLogout} className="bg-gray-900 flex
                text-white w-full rounded-md px-3 py-2 text-base font-medium no-underline">
                  Logout
                  <svg className='flex self-center ps-1' xmlns="http://www.w3.org/2000/svg" width="20"
                    height="18" viewBox="0 0 24 24" fill="none"
                    stroke="#fff" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"><path d="M16 17l5-5-5-5M19.8 12H9M10 3H4v18h6" />
                  </svg>
                </Link>
              )}
            </div>

          </div>
        </div>
      )}
    </nav >
  );
};

