// React
import React from 'react'

// React hook form
import { useForm } from 'react-hook-form';

// Axios instance
import authInstance from '../../../../axios/authInstance';

// Components
import Spinner from '../../../Spinner/Spinner';
import ShowModalButton from '../../showModalButton/ShowModalButton';
import Modal from '../../Modal/Modal';
import NoDataFound from '../../NoDataFound/NoDataFound';
import UsersTable from '../UsersTable/UsersTable';
import InputsError from '../../../InputsError/InputsError';

// React toastify
import { toast } from 'react-toastify';
import { toastTheme } from '../../../../utils/toastTheme';

// Custom hook
import usePasswordMatch from '../hook/usePasswordMatch';


const Users = ({ users, refetchUsers }) => {

    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const passwordMatch = usePasswordMatch(watch('password'), watch('confirmPassword'));

    const handleCreateUser = (data) => {

        if (!passwordMatch) return;

        setLoading(true);
        authInstance.post('admin/auth/signup', {
            username: data.username,
            password: data.password,
            role: data.role
        })
            .then(() => {
                setLoading(false)
                toast.success('User created successfully', toastTheme)
            })
            .catch(() => {
                setLoading(false)
                toast.error('Something went wrong', toastTheme)
            })
            .finally(() => {
                setShowModal(false)
                refetchUsers();
            })
    }


    if (loading) return <Spinner customStyles={'absolute top-0 left-0 w-full'} />

    return (
        <>
            <div className='flex w-full justify-between items-center gap-4 flex-wrap'>
                <h4 className="text-black w-2/4 font-medium">All Users</h4>
                <ShowModalButton type={'User'} show={() => setShowModal(true)} />
            </div>

            <Modal showModal={showModal} title={'Create New User'}  >
                {({ controls: Controls }) => <form onSubmit={handleSubmit(handleCreateUser)}>

                    <div className='pt-4 flex flex-col gap-4'>
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium
                                     text-gray-900">User Name :</label>
                            <input
                                {...register('username', { required: true })}
                                type="text" id="username"
                                className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                            {errors?.username &&
                                <InputsError errorText={'This field is required'} customStyles={'m-0'} />}

                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium
                                     text-gray-900">Password :</label>
                            <input
                                {...register('password', { required: true })}
                                type="password" id="password" data-testid='pass'
                                className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                            {errors?.password &&
                                <InputsError errorText={'This field is required'} customStyles={'m-0'} />}
                        </div>

                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium
                                     text-gray-900">Confirm Password :</label>
                            <input
                                {...register('confirmPassword', { required: true })}
                                type="password" id="confirmPassword" data-testid='confirm'
                                className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                            {(errors?.confirmPassword) ?
                                <InputsError errorText={'This field is required'} customStyles={'m-0'} />
                                :
                                (!passwordMatch && !errors.confirmPassword) &&
                                <InputsError errorText={'⚠ passwords do not match'} customStyles={'m-0'} />
                            }
                        </div>

                        <div>
                            <label htmlFor="role" className="block text-sm font-medium
                                     text-gray-900">User Role : </label>
                            <select className="dropdown-content"
                                {...register('role')}>
                                <option value={'USER'}>User</option>
                                <option value={'ADMIN'}>Admin</option>
                            </select>
                        </div>
                    </div>

                    <Controls onClose={() => setShowModal(false)} btnText={'Create New User'} />
                </form>}
            </Modal>


            {/* rendering the users */}
            {users?.length !== 0 ?
                <UsersTable users={users} refetchUsers={refetchUsers} loading={(value) => setLoading(value)} />
                :
                <NoDataFound sectionName={'Users'} />
            }
        </>
    )
}

export default Users