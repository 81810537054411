import React from 'react';
import Spinner from '../Spinner/Spinner';
import warning from '../../svg/warning.svg';

const ConfirmationDialogue = ({
                                  showModal, close, onConfirm, isLoading, title = 'Are you sure?',
                                  content, confirmButtonText = 'Yes', cancelButtonText = 'Cancel'
                              }) => {
    if (isLoading) return <Spinner customStyles={'absolute top-0 left-0 w-full h-full bg-black bg-opacity-50'} />;

    return (
        <>
            {showModal &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed
                        inset-0 z-50 outline-none focus:outline-none bg-opacity-100">
                        <div className="relative w-auto flex justify-center my-6 mx-auto max-w-3xl">
                            <div
                                className="border-0 rounded-lg relative flex flex-col w-2/3 bg-white outline-none
                                focus:outline-none p-4 items-center gap-2">
                                <img className='w-1/3' src={warning} alt='warning' loading='lazy'/>
                                <div
                                    className="flex flex-col items-center justify-center p-3 border-b border-solid
                                    border-blueGray-200 rounded-t">
                                    <h5 className="text-gray-800 font-semibold">
                                        {title}
                                    </h5>
                                    <h6 className='text-center'>
                                        {content}
                                    </h6>
                                </div>
                                <div className='w-full flex justify-around items-center'>
                                    <button onClick={close} className='bg-gray-300 px-3 py-2 rounded'>
                                        {cancelButtonText}
                                    </button>
                                    <button onClick={onConfirm} className='bg-red-500 px-3 py-2 rounded text-gray-50'>
                                        {confirmButtonText}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }
        </>
    );
}

export default ConfirmationDialogue;
