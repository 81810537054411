import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// Components
import ThreeDS from '../../components/PaymentForm/ThreeDS';
import Spinner from '../../components/Spinner/Spinner';
import PaymentForm from '../../components/PaymentForm/PaymentForm';
import OrderSummary from '../../components/orderSummary/OrderSummary';

export const Payment = ({ setModal }) => {
  const { createPaymentRes, createPaymentLoading } = useSelector((state) => state.createPaymentReducer);
  const { createOrderLoading } = useSelector((state) => state.createOrderReducer);
  const { challengeLoading } = useSelector((state) => state.challengeReducer);



  if (createPaymentLoading || createOrderLoading || challengeLoading) return <Spinner />

  return (
    <div className='grid grid-cols-12'>
      {createPaymentRes['3ds2'] ?
        <ThreeDS threeDsData={createPaymentRes} setModal={setModal} />
        :
        <>
          <PaymentForm setModal={setModal} />
          <OrderSummary />
        </>
      }
    </div>


  );
}

