

export const getMinorUnit = (currencyCode) => {
    if (!currencyCode && currencyCode === 'ALL') { return 2; }
    return getMinorUnitBy(currencyCode);
};

const getMinorUnitBy = (currencyCode) => {

    const numberToCurrency = Intl.NumberFormat('en', {
        style: 'currency',
        currency: currencyCode || 'AED'
    })
    
    // destruct the minimum number of digits after the decimal for each currency
    const { minimumFractionDigits } = numberToCurrency.resolvedOptions();
    return minimumFractionDigits;
};

export const isNumeric = (value) => /^\d+$/.test(value);

export const isKsaRegion = () => ['ksa'].some(part => window.location.hostname.includes(part));