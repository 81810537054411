import * as CREATE_PAYMENT_CONSTANTS from './constants';


const initialState = {
    createPaymentRes: {},
    createPaymentLoading: false,
    paymentError: null,

    threeDsAuthRes: {},
    authLoading: false,
    threeDsAuthError: null
}


const createPaymentReducer = (state = initialState, action) => {

    switch (action.type) {

        case CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_SUCCESS:
            return { ...state, createPaymentRes: action.payload, createPaymentLoading: false };
        case CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_LOADING:
            return { ...state, createPaymentLoading: true };
        case CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_ERROR:
            return { ...state, paymentError: action.payload, createPaymentLoading: false };

        case CREATE_PAYMENT_CONSTANTS.AUTH_CALL_SUCCESS:
            return { ...state, threeDsAuthRes: action.payload, authLoading: false };
        case CREATE_PAYMENT_CONSTANTS.AUTH_CALL_LOADING:
            return { ...state, authLoading: true };
        case CREATE_PAYMENT_CONSTANTS.AUTH_CALL_ERROR:
            return { ...state, threeDsAuthError: action.payload, authLoading: false };

        case CREATE_PAYMENT_CONSTANTS.RESET_CREATE_PAYMENT_REDUCER:
            return initialState;

        default:
            return state;
    }
}
export default createPaymentReducer;