// React
import React, { Fragment, useEffect, useState } from 'react';

// Form context
import { useFormContext } from 'react-hook-form';

// Components
import { TextInput } from '../../text-input/text-input';
import { RadioOptions } from '../../radio-option/radio-options';
import InputsError from '../../InputsError/InputsError';
import Dropdown from '../../dropdown/dropdown';

function KsaPaymentAction() {
  const paymentActions = ['AUTH', 'PURCHASE', 'VERIFY', 'RECURRING', 'UNSCHEDULED', 'INSTALLMENT'];
  const recurringOrderTypes = ['FIXED', 'VARIABLE'];
  const recurringFrequency = ['HOURLY', 'WEEKLY', 'MONTHLY', 'YEARLY'];
  const unscheduledTypes = ['AUTH', 'PURCHASE', 'SUBSEQUENT'];

  const { register, formState: { errors }, watch, setValue } = useFormContext();

  const [isRecurringPayment, setIsRecurringPayment] = useState(false);
  const [isInstallment, setIsInstallment] = useState(false);
  const [isUnscheduled, setIsUnscheduled] = useState(false);
  const [isPurchaseOrAuth, setIsPurchaseOrAuth] = useState(false);
  const [selectedUnscheduledType, setSelectedUnscheduledType] = useState('');

  const currentPaymentOption = watch('paymentAction');
  const currentUnscheduledOption = watch('unscheduledType');
  const isSavedCard = watch('isSavedCard');

  useEffect(() => {
      setIsPurchaseOrAuth(['PURCHASE', 'AUTH'].includes(currentPaymentOption));
      setIsRecurringPayment(currentPaymentOption === 'RECURRING');
      setIsInstallment(currentPaymentOption === 'INSTALLMENT');
      setIsUnscheduled(currentPaymentOption === 'UNSCHEDULED');
  }, [currentPaymentOption]);

  useEffect(() => {
      if (isUnscheduled) {
          if(currentUnscheduledOption != 'SUBSEQUENT'){
              setValue('parentOrderReference', '');
          }
          setSelectedUnscheduledType(currentUnscheduledOption);
      } else {
          setSelectedUnscheduledType('');
          setValue('parentOrderReference', '');
      }
  }, [currentUnscheduledOption, isUnscheduled]);

  return (
    <Fragment>
      <div className='flex flex-col mt-2'>
        <div className='flex flex-col items-start gap-2'>
          <p className='text-gray-100 text-lg inline my-auto'>Payment Action</p>
          <RadioOptions options={paymentActions} name="paymentAction" isRequired={true} />
        </div>
        {errors?.paymentAction && <InputsError errorText={errors.paymentAction.message} customStyles={'mt-2'} />}
      </div>

      {isRecurringPayment &&
        <Dropdown heading='Order Type'
            menuOptions={recurringOrderTypes}
            name='recurringOrderType'
            isInputActive={false}
            disableOtherOption />
      }

      {(isRecurringPayment || isInstallment) &&
        <Dropdown heading='Frequency'
            menuOptions={recurringFrequency}
            name='recurringFrequency'
            isInputActive={false}
            disableOtherOption />
      }

      {isRecurringPayment &&
        <TextInput name='numberOfTenure' heading='Number Of Tenure' placeholder='Keep blank for undefined recurring' />
      }

      {isInstallment &&
        <TextInput
            name='numberOfInstallmentTenure'
            heading='Number Of Tenure'
            placeholder='Enter number of tenure'
        />
      }
      {isInstallment && errors?.numberOfInstallmentTenure &&
          <InputsError errorText={errors.numberOfInstallmentTenure.message} customStyles={'mt-2'} />
      }

      {isUnscheduled &&
          <Dropdown heading='Unscheduled Type'
            menuOptions={unscheduledTypes}
            name='unscheduledType'
            isInputActive={false}
            disableOtherOption
          />
      }
      {selectedUnscheduledType === 'SUBSEQUENT' && (
            <TextInput
                name="parentOrderReference"
                heading="Parent Order Reference"
                placeholder="Enter parent order reference"
            />
      )}
      {selectedUnscheduledType === 'SUBSEQUENT' && errors?.parentOrderReference &&
          <InputsError errorText={errors.parentOrderReference.message} customStyles={'mt-2'} />}

      <TextInput
        pattern={/^[A-Za-z]+$/}
        name="email"
        heading="Email Address"
        placeholder="Enter email address"
      />
      {errors?.email && <InputsError errorText={errors.email.message} customStyles={'mt-2'} />}

      <div className='flex justify-start items-center'>
        <input
          {...register('isSavedCard')}
          className="h-4 w-4 shrink-0 accent-ni-red"
          type="checkbox"
          id="default-checkbox"
          label="Saved Card"
        />
        <span id="terms-description"
          className="p-2 text-sm text-gray-100">Saved Card</span>
      </div>
      {errors?.isSavedCard && <InputsError errorText={errors.isSavedCard.message} customStyles={'mt-2'} />}

      {isSavedCard && (
            <TextInput
                name="savedCardOrderReference"
                heading="Saved Card Order Reference"
                placeholder="Enter saved card order ref"
            />
      )}
      {isSavedCard && errors?.savedCardOrderReference &&
          <InputsError errorText={errors.savedCardOrderReference.message} customStyles={'mt-2'} />}

        <>
            <TextInput
                name="customAmount"
                heading="Custom amount (eg: 101 for SAR 1.01)"
                placeholder="Enter custom amount"
            />
            <span htmlFor="heading" className="mb-2 block text-xs font-medium text-gray-100 normal-case">
                Cart item overrides the custom amount
            </span>
        </>
        {errors?.customAmount && <InputsError errorText={errors.customAmount.message} customStyles={'mt-2'}/>}

        {isPurchaseOrAuth &&
        <TextInput
          name="passengerNameRecord"
          heading="Airline booking"
          placeholder="Enter PNR details for airline booking"
        />
      }
      {errors?.passengerNameRecord && (
        <InputsError
          errorText={errors.passengerNameRecord.message}
          customStyles={'mt-2'}
        />
      )}
    </Fragment>);
}

export default KsaPaymentAction;
