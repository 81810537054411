// React
import React, { useState } from 'react';

// SVG
import deleteIcon from '../../../../svg/delete.svg';

// Components
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';

const ItemsList = ({ item, type, refetchOutlets }) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ConfirmationModal info={{ type: 'outlets', reference: item?.reference }}
                showModal={showModal}
                close={() => setShowModal(false)}
                refetchItems={refetchOutlets} />
            <div className={`flex justify-between items-center bg-[#173453] w-full p-3 text-gray-300
                           ${type === 'outlet' ?
                    'hover:cursor-default bg-gray-50 text-ni-blue border-2 border-ni-blue'
                    : 'group-hover:bg-[rgb(43,68,95)] group-hover:text-white'}`}>
                <div>
                    <p className={`text-sm md:text-lg lg:text-lg text-gray-100
                                   inline leading-none m-0 ${type === 'outlet' ? 'font-bold text-ni-blue' : ''}`}>
                        {item.name}
                    </p>
                    {type === 'outlet' && <span> : {item?.reference}</span>}
                </div>



                {type !== 'outlet' ?
                    // normal arrow for tenant and merchant items
                    <svg
                        className='transition-all group-hover:translate-x-3'
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h13M12 5l7 7-7 7" />
                    </svg>
                    :
                    <img onClick={() => setShowModal(true)}
                        className='hover:scale-125 cursor-pointer' src={deleteIcon} alt='delete' />
                }
            </div>
        </>
    )
}

export default ItemsList