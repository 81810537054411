// React
import React, { Fragment, useEffect } from 'react';

// Hook form
import { useFormContext } from 'react-hook-form';

// Components
import InputsError from '../InputsError/InputsError';

// Redux
import { useSelector } from 'react-redux';
import { RadioOptions } from '../radio-option/radio-options';

function CustomDropDown({ currentTenantMerchants, isInputActive }) {
  const { envId } = useSelector((state) => state.paymentRequestReducer);
  const { register, resetField, watch, formState: { errors } } = useFormContext();

  useEffect(() => {
    resetField('merchantAndOutlet')
  }, [isInputActive, envId])

  return (
    <div className="dropdown">
      {/* created custom drop down only for special case */}
      {isInputActive ?
        <>
          <label htmlFor="merchantAndOutlet" className="mb-2 block text-sm font-medium text-gray-100"
          >Outlet Reference
          </label>
          <input
            {...register('merchantAndOutlet', {
              required: { value: true, message: 'Outlet reference is required' }, shouldUnregister: true
            })}
            placeholder="Outlet reference"
            className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11
                           text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
            type="text"
          />
          {errors?.merchantAndOutlet &&
            <InputsError errorText={errors?.merchantAndOutlet?.message} customStyles={'mt-2'} />}

          <hr className='w-full text-ni-red' />

          <section className='mt-2'>
            <RadioOptions options={['apiKey', 'accessToken']}
              name="keyOrAccess"
              isRequired={true}
              additionalStyles={'flex flex-wrap'} />
          </section>
          {errors?.keyOrAccess && <InputsError errorText={errors?.keyOrAccess?.message} customStyles={'mt-2'} />}

          <input
            {...register('keyOrAccessValue', {
              required: { value: true, message: ' This field is Required' }, shouldUnregister: true
            })}
            placeholder={watch('keyOrAccess') === null ? 'choose input type' : '' ||
              (watch('keyOrAccess') === 'accessToken') ? 'Access Token' : 'API Key'}
            defaultValue={''}
            className="block w-full rounded-md border border-gray-200 mt-2 py-3 px-4 pr-11
                         text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
            type="text" />
          {errors?.keyOrAccessValue &&
            <InputsError errorText={errors?.keyOrAccessValue?.message} customStyles={'mt-2'} />}
        </>

        :
        <>
          <select className="dropdown-content"
            {...register('merchantAndOutlet', {
              required: { value: true, message: 'Outlet reference is required' }, shouldUnregister: true
            })}>
            <option value={''}>Select Outlet</option>
            {currentTenantMerchants?.map((merchant) => (
              <Fragment key={merchant.name}>
                {merchant.outlets?.map((outlet) => (
                  <option className="dropdown-option"
                    key={outlet.name}
                    value={outlet.reference + '|' + merchant.id}>
                    {merchant.name + ' | ' + outlet.name}
                  </option>
                ))}
              </Fragment>
            ))}
          </select>
          {errors?.merchantAndOutlet &&
            <InputsError errorText={errors?.merchantAndOutlet?.message} customStyles={'mt-2'} />}
        </>

      }

    </div>);
}

export default CustomDropDown;
