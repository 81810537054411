// Constants
import * as CART_CONSTANTS from './constants';


const initialState = {
    cartItems: []
};

const findItemIndex = (cartItems, item) => {
    const itemIndex = cartItems.findIndex((singleItem) => singleItem.id === item.id);
    return itemIndex;
}


const cartReducer = (state = initialState, action) => {
    let itemIndex;

    // separate the logic of add-increase & remove-decrease ===> for the sake of readability 
    switch (action.type) {
        case CART_CONSTANTS.ADD_TO_CART:
            const currentItem = state.cartItems.find((item) => item.id === action.payload.id);
            if (!currentItem) {
                return { ...state, cartItems: [...state.cartItems, { ...action.payload, quantity: 1 }] };
            }
            return state;

        case CART_CONSTANTS.ADD_QUANTITY:
            itemIndex = findItemIndex(state.cartItems, action.payload);
            state.cartItems[itemIndex].quantity += 1;
            return { ...state, cartItems: [...state.cartItems] };

        case CART_CONSTANTS.REMOVE_FROM_CART:
            itemIndex = findItemIndex(state.cartItems, action.payload);
            state.cartItems.splice(itemIndex, 1);
            return { ...state, cartItems: [...state.cartItems] };

        case CART_CONSTANTS.SUB_QUANTITY:
            itemIndex = findItemIndex(state.cartItems, action.payload);
            if (state.cartItems[itemIndex].quantity > 1) {
                state.cartItems[itemIndex].quantity -= 1;
                return { ...state, cartItems: [...state.cartItems] }
            } else {
                state.cartItems.splice(itemIndex, 1);
                return { ...state, cartItems: [...state.cartItems] };
            }

        case CART_CONSTANTS.EMPTY_CART:
            return initialState;

        default:
            return state;
    }
}


export default cartReducer;