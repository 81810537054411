import * as CREATE_ORDER_CONSTANTS from './constants';

const initialState = {
  paymentLink: '',
  reference: '',
  createOrderLoading: false,
  error: null
};


export const createOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_CONSTANTS.FETCH_START_LOADING:
      return { ...state, createOrderLoading: true };

    case CREATE_ORDER_CONSTANTS.FETCH_SUCCESS:
      return { ...state,
        createOrderLoading: false,
        paymentLink: action.payload.paymentLink,
        reference: action.payload.reference };

    case CREATE_ORDER_CONSTANTS.FETCH_ERROR:
      return { ...state, createOrderLoading: false, error: action.payload };

    default:
      return state;
  }
};

export default createOrderReducer;

