// React
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Action creator
import { threeDSAuthCall } from '../../redux/createPaymentReducer/actions';
import { getChallengeResult } from '../../redux/challengeReducer/actions';


// Router dom
import { useNavigate } from 'react-router-dom';
// styles
import './style.css'

// Components
import Spinner from '../Spinner/Spinner';


function ThreeDS({ threeDsData, setModal }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const challengeSubmitRef = useRef();
    const [messageData, setMessageData] = useState('');

    const { threeDsAuthRes, createPaymentLoading } = useSelector((state) => state.createPaymentReducer);
    const { challengeResponse, challengeLoading } = useSelector((state) => state.challengeReducer);

    const base64EncodedCReq = threeDsAuthRes['3ds2']?.base64EncodedCReq;
    const orderRef = threeDsAuthRes?.orderReference;


    let acsURL = threeDsAuthRes['3ds2']?.acsURL;
    if (acsURL && acsURL.includes('localhost')) {
        // applies only for local env
        acsURL = acsURL?.replace('https://localhost', 'http://localhost:3000');
    }

    const authenticationCall = useCallback(() => {
        dispatch(threeDSAuthCall(threeDsData));
    }, [threeDsData])

    useEffect(() => {
        authenticationCall();
    }, [authenticationCall]);

    useEffect(() => {
        const orderState = threeDsAuthRes?.state;
        const orderTransStatus = threeDsAuthRes['3ds2']?.transStatus;
        if (orderState === 'AWAIT_3DS' && orderTransStatus === 'C') {
            challengeSubmitRef.current.click();
        } else if (orderTransStatus !== undefined && orderTransStatus !== 'C') {
            // The payment is done and not require a challenge
            setModal({ show: true, data: orderState, orderRef })
            navigate('/products', { replace: true });
        }
    }, [threeDsAuthRes]);


    // listening to the message event for complete the challenge 
    useEffect(() => {
        window.addEventListener('message', messageReceiver);
        return () => {
            window.removeEventListener('message', messageReceiver);
        }
    }, [messageReceiver]);

    // Getting the value of the message and store it in a state
    var messageReceiver = useCallback((message) => {
        setMessageData(JSON.stringify(message.data))
    }, [threeDsAuthRes]);

    // Do the challenge api call once the message of completion is exist
    useEffect(() => {
        if (messageData?.includes('3DS2_CHALLENGE_COMPLETE')) {
            const base64EncodedCRes = messageData.split(':')[1].slice(1, -1);
            dispatch(getChallengeResult(base64EncodedCRes));
        }
    }, [messageData]);

    // Navigate the user once the challenge completed 🚀
    useEffect(() => {
        if (challengeResponse?.state) {
            setModal({ show: true, data: challengeResponse.state, orderRef })
            navigate('/products', { replace: true })
        }
    }, [challengeResponse]);


    if (createPaymentLoading || challengeLoading) return <Spinner />

    return (
        <div>
            <form method='post' action={acsURL} target='threeDsIframe' className='d-none'>
                <input type='hidden' name='creq' value={base64EncodedCReq} />
                <input type='submit' ref={challengeSubmitRef} />
            </form>
            <iframe
                className='flex justify-center items-center w-screen h-screen'
                title='3DS'
                data-testid='threeDsIframe'
                name='threeDsIframe'
                id='threeDsIframe'
            />
        </div>
    );
}

export default ThreeDS;