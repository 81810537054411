export const currenciesExchangeRates = {
    USD: {
        EUR: 0.85,
        GBP: 0.75,
        INR: 73.57,
        AED: 3.67,
        EGP: 15.70,
        JOD: 0.71
    },
    EUR: {
        USD: 1.18,
        GBP: 0.88,
        INR: 86.53,
        AED: 4.31,
        EGP: 17.76,
        JOD: 0.81
    },
    GBP: {
        USD: 1.33,
        EUR: 1.14,
        INR: 98.92,
        AED: 4.88,
        EGP: 20.14,
        JOD: 0.91
    },
    AED: {
        USD: 0.27,
        EUR: 0.23,
        GBP: 0.20,
        INR: 20.15,
        EGP: 4.61,
        JOD: 0.46
    },
    EGP: {
        USD: 0.056,
        EUR: 0.056,
        GBP: 0.049,
        INR: 4.34,
        AED: 0.22,
        JOD: 0.045
    },
    JOD: {
        USD: 1.40,
        EUR: 1.24,
        GBP: 1.10,
        INR: 111.20,
        AED: 2.18,
        EGP: 22.13
    }
};
