import * as AUTH_CONSTANTS from './constants';

const initState = {
  isAuth: !!(localStorage.getItem('accessToken') ?
    localStorage.getItem('accessToken').includes('eyJhbGciOiJIUzUxMiJ9')
    : '') || false,
  loading: false,
  error: null,
  loginFailed: '',
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  isAdmin: false,
  adminLoading: true
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_CONSTANTS.LOADING_START: {
      return { ...state, loading: true };
    }

    case AUTH_CONSTANTS.ADMIN_LOADING_START: {
      return { ...state, adminLoading: true }
    }

    case AUTH_CONSTANTS.AUTH_SUCCESS: {
      return {
        ...state,
        isAuth: true,
        loading: false,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
    }

    case AUTH_CONSTANTS.AUTH_FAILED: {
      return { ...state, loginFailed: action.payload, loading: false };
    }

    case AUTH_CONSTANTS.AUTH_ERROR: {
      return { ...state, error: action.payload, loading: false };
    }

    case AUTH_CONSTANTS.CHECK_AUTH: {
      return { ...state, isAuth: true, loading: false };
    }

    case AUTH_CONSTANTS.LOGOUT_USER: {
      return { ...state, isAuth: false, loading: false, accessToken: '', refreshToken: '' };
    }

    case AUTH_CONSTANTS.FORCE_LOGOUT: {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return { ...state, isAuth: false, loading: false, accessToken: '', refreshToken: '' };
    }

    case AUTH_CONSTANTS.HANDLE_REFRESH_TOKEN: {
      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      return {
        ...state,
        isAuth: true,
        loading: false,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
    }

    case AUTH_CONSTANTS.IS_ADMIN: {
      return { ...state, isAdmin: action.payload, adminLoading: false }
    }

    case AUTH_CONSTANTS.UPDATE: {
      return { ...state, accessToken: action.payload.accessToken, refreshToken: action.payload.refreshToken }
    }
    default:
      return state;
  }
};


export default authReducer;
