// Constants
import * as ORDER_DETAILS_CONSTANTS from './constants';

// Auth instance
import authInstance from '../../axios/authInstance';

// Action creator
import { resetPaymentRequestData } from '../paymentRequestReducer/actions';


export const getOrderDetails = (orderDetailsBody) => (dispatch) => {


  dispatch({ type: ORDER_DETAILS_CONSTANTS.FETCH_LOADING_START })

  try {
    authInstance.post('order/detail',
      orderDetailsBody,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => dispatch({ type: ORDER_DETAILS_CONSTANTS.FETCH_SUCCESS, payload: response }))
      .catch((error) => {
        dispatch({ type: ORDER_DETAILS_CONSTANTS.FETCH_FAILED, payload: error });
      })

  } catch (error) {
    dispatch({ type: ORDER_DETAILS_CONSTANTS.FETCH_FAILED, payload: error });
  }

  finally {
    dispatch({ type: ORDER_DETAILS_CONSTANTS.EMPTY_LOCAL_STORAGE })
    dispatch(resetPaymentRequestData());
  }

}

export const resetOrderDetails = () => (dispatch) => {
  dispatch({ type: ORDER_DETAILS_CONSTANTS.RESET_DETAILS })
}