// React
import React, { useEffect, useState } from 'react';

// Props type
import PropTypes from 'prop-types';

// Styles
import './style.css';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Action creators
import { addQuantity, addToCart, subQuantity } from '../../redux/cartReducer/actions';

export const Item = ({ item, currentCurrency }) => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cartReducer);
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    // check weather the current item is exist in the cart ?
    setCurrentItem(cartItems?.find((singleItem) => singleItem.id === item.id));
  }, [cartItems])

  return (
    <div>
      <div className="group pb-5 w-full aspect-square flex flex-col gap-1">
        <div
          className="xs:w-0 overflow-hidden rounded-lg bg-gray-200 md:w-full aspect-square">
          <img src={item?.image}
            alt="Product"
            loading="lazy"
            className="h-full w-full object-cover object-center group-hover:opacity-75" />
        </div>
        <h3 className="mt-6 text-sm text-gray-700">{item?.name}</h3>
        <p className="mt-1 mb-4 text-lg font-medium text-gray-900">
          {currentCurrency} {Number(item?.amount).toFixed(0)}
        </p>
        {!!currentItem || (
          <button
            onClick={() => dispatch(addToCart(item))}
            className="flex align-self-center items-center justify-center rounded-md bg-ni-red text-gray-200
             w-full px-5 py-2.5 text-center text-sm font-medium hover:bg-red-500 focus:outline-none focus:ring-4
             focus:ring-red-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0
                        0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
            </svg>
            Add to cart
          </button>
        )}
        {!!currentItem && (
          <div className="flex items-center justify-evenly rounded-md bg-ni-red py-2.5 text-center text-sm
                 font-medium">
            <button className="h-6 hover:bg-red-300 font-bold px-3 rounded text-gray-200"
              onClick={() => dispatch(subQuantity(item))}>-
            </button>
            <div className="h-6 font-bold px-4 text-gray-200">{currentItem?.quantity}</div>
            <button className="h-6 hover:bg-red-300 font-bold px-3 rounded text-gray-200"
              onClick={() => dispatch(addQuantity(item))}>+
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  })
};

