import axios from 'axios';
import { store } from '../redux/store'
import { updateAccessAndRefreshTokens } from '../redux/authReducer/actions';


const authInstance = axios.create({
  baseURL: '/api/'
});


authInstance.interceptors.request.use(function (config) {

  if (config?.url.includes('refreshToken')) {
    return config
  } else {
    const { accessToken } = store.getState().authReducer;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }
}, function (error) {
  return Promise.reject(error);
});

authInstance.interceptors.response.use((res) => {

  return res;
}, async (err) => {

  if (err.response.status === 401 && !err.response?.config?.url?.includes('refreshToken')) {
    try {
      const oldRefreshToken = localStorage.getItem('refreshToken');
      const { data } = await authInstance.post('auth/refreshToken', { refreshToken: oldRefreshToken }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const { refreshToken, accessToken } = data;
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)

      // update refresh token and access token in the store
      store.dispatch(updateAccessAndRefreshTokens(accessToken, refreshToken))
      err.config.headers['Authorization'] = `Bearer ${accessToken}`;
      return authInstance.request(err.config);

    } catch (error) {
      store.dispatch({ type: 'FORCE_LOGOUT' })
      return Promise.reject(err);
    }
  } else if (err.response.status === 401 && err.response.config.url.includes('refreshToken')) {
    // only when the refresh token API gets a 401 this means that both refresh and access tokens are expired 😁
    // need to be handled in another way from the back-end
    store.dispatch({ type: 'FORCE_LOGOUT' })
  }

  return Promise.reject(err)
})

export default authInstance;
