// React
import React, { useEffect, useState } from 'react';

// Props types
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './env-section.style.css';

// Component
import { RadioOptions } from '../../radio-option/radio-options';
import Dropdown from '../../dropdown/dropdown';
import CustomDropDown from '../../CustomDropDown/CustomDropDown';
import InputsError from '../../InputsError/InputsError';

// Action creators
import { updateEnv } from '../../../redux/paymentRequestReducer/actions';
import { updateSelectedTenant } from '../../../redux/environmentsReducer/actions';

// Form context
import { useFormContext } from 'react-hook-form';


export const EnvSection = ({ envOptions }) => {
  const dispatch = useDispatch();
  const { envId } = useSelector((state) => state.paymentRequestReducer);
  const { allEnvs } = useSelector((state) => state.environmentsReducer);

  const [currentEnvTenants, setCurrentEnvTenants] = useState([]);
  const [currentTenantMerchants, setCurrentTenantMerchants] = useState([]);
  const [isInputActive, setIsInputActive] = useState(false);

  const { formState: { errors }, watch, resetField } = useFormContext();

  useEffect(() => {
    // once the env is exist we need to get all tenants for this ENV
    const currentEnvData = allEnvs.find((singleEnv) => singleEnv.id === envId);
    const { tenantConfigs } = currentEnvData ? currentEnvData : [];
    setCurrentEnvTenants(tenantConfigs);
    setCurrentTenantMerchants([]);

    // return the input active to false when the Env changes
    setIsInputActive(false);
  }, [envId]);

  useEffect(() => {
    if (watch('tenant') === 'other') {
      setIsInputActive(true);
      resetField('tenant')
    } else {
      const tenant = currentEnvTenants?.find((tenant) => tenant.name === watch('tenant'));
      // UPDATE THE STATE WITH THE SELECTED TENANT
      dispatch(updateSelectedTenant(tenant));
      setCurrentTenantMerchants(tenant?.merchantConfigs);
    }
  }, [watch('tenant')])


  useEffect(() => {
    dispatch(updateEnv(watch('envOptions'), allEnvs))
  }, [watch('envOptions')])

  return (
    <div className="env-section over">
      <div className='flex flex-col items-start gap-2'>
        <p className='text-gray-100 text-lg inline-block my-auto font-medium'>Environments</p>
        <div className='flex justify-between w-full'>

          <RadioOptions options={envOptions} name="envOptions" isRequired={true} />
          {isInputActive &&
            // button to reset if the use don't need to type tenant name any more
            <button onClick={() => {
              resetField('tenant')
              setIsInputActive(false)
            }}
              type='button'
              className='group bg-slate-50 rounded-md py-1 px-1 w-auto h-4/6'>
              <p className='group-hover:text-ni-red-dark group-hover:font-semibold d-inline '>Reset</p>
            </button>
          }
        </div>
      </div>

      {errors?.envOptions && <InputsError errorText={errors?.envOptions?.message} customStyles={'mt-2'} />}

      {envId &&
        <div>
          <Dropdown heading={'Select Tenant'}
            isInputActive={isInputActive}
            name={'tenant'}
            menuOptions={currentEnvTenants?.map((tenant) => tenant.name)} />
          {errors?.tenant && <InputsError errorText={errors?.tenant?.message} customStyles={'mt-2'} />}

          <CustomDropDown currentTenantMerchants={currentTenantMerchants} isInputActive={isInputActive} />
        </div>
      }
    </div>
  );
};

EnvSection.propTypes = {
  envOptions: PropTypes.arrayOf(PropTypes.string).isRequired
};
