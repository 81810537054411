// React
import React, { useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Item } from '../Item/item';

// styles
import './style.css';

// Action creators
import { updateTotal } from '../../redux/paymentRequestReducer/actions';
import { emptyCart } from '../../redux/cartReducer/actions';

// Spinner
import Spinner from '../Spinner/Spinner';


export const Catalogue = ({ showNotify }) => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cartReducer);
  const { products, currentCurrency } = useSelector((state) => state.productsReducer);

  useEffect(() => {
    dispatch(emptyCart());
  }, [])

  useEffect(() => {
    const total = cartItems?.reduce((acc, currentItem) => acc + (currentItem.amount * currentItem.quantity), 0);
    dispatch(updateTotal(total));
  }, [cartItems]);

  if (!products) return <Spinner />

  return (
    <div className="mx-auto max-w-2xl px-6 pt-12 pb-5 lg:max-w-7xl lg:px-6">
      {showNotify &&
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2" role="alert">
          <strong className="font-bold">Error : </strong>
          <span className="block sm:inline"> You need to add at least one item to the cart to proceed</span>
        </div>
      }
      <div className="grid grid-cols-1 py-10 md:py-0 lg:py-0
       gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
      >
        {products?.map((item, index) => (
          <Item
            key={index}
            item={item}
            currentCurrency={currentCurrency}
          />
        ))}
      </div>
    </div>
  );
};
