// Router dom
import { Link, useParams } from 'react-router-dom';

// Styles
import './style.css'

// Components
import Modal from '../../Modal/Modal';
import ItemsList from '../itemsList/ItemsList';
import NoDataFound from '../../NoDataFound/NoDataFound';
import ShowModalButton from '../../showModalButton/ShowModalButton';

// React hook form
import { useForm } from 'react-hook-form';

// React
import React, { useState } from 'react';

// Axios instance
import authInstance from '../../../../axios/authInstance';

// React toastify
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { toastTheme } from '../../../../utils/toastTheme';


const Tenants = ({ tenants, refetchTenants }) => {
    const params = useParams();
    const { environment, envId } = params;

    const [showModal, setShowModal] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {

        authInstance.post('admin/config/tenants', {
            envId: envId,
            name: data?.tenant
        })
            .then(() => {
                toast.success('Tenant created successfully', toastTheme);
            })
            .catch(() => {
                toast.error('Something went wrong', toastTheme)
            })
            .finally(() => {
                setShowModal(false)
                refetchTenants();
                reset();
            })
    }


    return (
        <>
            <div className='flex justify-center items-center'>
                <p className='text-center text-sm md:text-xl mb-4 lg:text-xl font-[600] text-gray-800'>
                    Tenants Of {environment}
                </p>
            </div>

            <Modal showModal={showModal} title={'Create New Tenant'} >
                {({ controls: Controls }) => <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='pt-4'>
                        <label htmlFor="tenantName" className="block text-sm font-medium
                                     text-gray-900">Tenant Name :</label>
                        <input
                            {...register('tenant', { required: true })}
                            type="text" id="tenantName"
                            className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500 mt-2"/>
                        {errors?.tenant && <p className='text-red-500 mb-0 block font-normal'>
                            This field is required
                        </p>}
                        <Controls btnText={'Create Tenant'} onClose={() => setShowModal(false)} />
                    </div>
                </form>}

            </Modal>
            {
                tenants?.length !== 0 ?
                    (<div className='flex flex-col gap-3 h-3/4 overflow-y-auto'>
                        {tenants?.map((tenant) => (
                            <div key={tenant?.id} className='w-full group'>
                                <Link className='no-underline' key={tenant?.name} to={`${tenant?.name}/${tenant?.id}`} >
                                    <ItemsList item={tenant} type={'tenant'} />
                                </Link>
                            </div>
                        ))
                        }
                    </div >)
                    :
                    <NoDataFound sectionName={'Tenants'} />
            }
            <ShowModalButton type={'Tenant'} show={() => setShowModal(true)} />
        </>
    )
}

export default Tenants;