import * as CHALLENGE_CONSTANTS from './constants';

// Axios instance
import authInstance from '../../axios/authInstance';

export const getChallengeResult = (base64EncodedCRes) => (dispatch, getState) => {

    const { paymentRequestReducer: { order, ...restOfPaymentData } } = getState();
    const { createPaymentReducer: { createPaymentRes: { orderReference, reference: paymentReference } } } = getState();

    dispatch({ type: CHALLENGE_CONSTANTS.CHALLENGE_CALL_LOADING })

    authInstance.post('3ds2/challenge',
        {
            ...restOfPaymentData,
            orderReference,
            paymentReference,
            challenge: {
                base64EncodedCRes
            }
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then((response) => dispatch({ type: CHALLENGE_CONSTANTS.CHALLENGE_CALL_SUCCESS, payload: response.data }))
        .catch((error) => {
            dispatch({ type: CHALLENGE_CONSTANTS.CHALLENGE_CALL_ERROR, payload: error })
        })
};

export const resetChallengeData = () => (dispatch) => {
    dispatch({ type: CHALLENGE_CONSTANTS.RESET_CHALLENGE_DATA })
}