// React
import React from 'react';

// Router Dom
import { useNavigate } from 'react-router-dom';

export const LandingPage = () => {
  const navigate = useNavigate();

  const login = () => {
    navigate('/login');
  };

  return (

    <div className="flex h-[calc(100vh-theme('spacing.16'))] w-full">
      <div className="flex flex-col md:w-full lg:w-full bg-home">
        <div
          className="my-auto flex flex-col px-6 pt-8 sm:px-24 md:justify-start md:px-8 md:pt-0 lg:px-12">
          <p className="text-6xl text-ni-blue font-bold">Ngenius Online</p>
          <p className="text-6xl text-ni-blue font-bold">Demo Store</p>
          <p className="text-2xl text-ni-blue-light mt-2">Witness smooth payments on our demo store today!</p>
          <div
            onClick={login}
            className="group flex lg:w-1/6 cursor-pointer items-center justify-center rounded-md
            bg-ni-red px-6 py-2 text-white transition">
            <span
              className="group flex w-full items-center justify-center rounded py-1
              text-center font-bold">Login</span>
            <svg className="flex-0 ml-4 h-6 w-6 transition-all group-hover:ml-8" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
            </svg>
          </div>
        </div>

      </div>
    </div>
  );
};

