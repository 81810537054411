// React
import React, { Fragment, useEffect, useState } from 'react';

// Form context
import { useFormContext } from 'react-hook-form';

// Redux
import { useDispatch } from 'react-redux';

// Components
import { TextInput } from '../../text-input/text-input';
import { RadioOptions } from '../../radio-option/radio-options';
import InputsError from '../../InputsError/InputsError';
import Dropdown from '../../dropdown/dropdown';

// Action creator
import { updateProductsCurrency } from '../../../redux/productsReducer/actions';

import { isKsaRegion } from '../../../utils';

function PaymentAction() {
  const dispatch = useDispatch();
  const paymentActions = ['AUTH', 'SALE', 'PURCHASE'];
  const currencyOptions = isKsaRegion() ? ['SAR'] : ['AED', 'USD', 'EUR', 'EGP', 'JOD', 'GBP'];

  const { register, formState: { errors }, watch, resetField } = useFormContext();

  const [isInputActive, setIsInputActive] = useState(false);
  const currentCurrency = watch('currency')

  useEffect(() => {
    if (currentCurrency === 'other') {
      resetField('currency')
      setIsInputActive(true)
    }

    if (currentCurrency) {
      dispatch(updateProductsCurrency(currentCurrency.toUpperCase()));
    }

  }, [currentCurrency])

  return (
    <Fragment>

      <div className='flex flex-col mt-2'>
        <div className='flex flex-col items-start gap-2'>
          <p className='text-gray-100 text-lg inline my-auto'>Payment Action</p>
          <RadioOptions options={paymentActions} name="paymentAction" isRequired={true} />
        </div>
        {errors?.paymentAction && <InputsError errorText={errors.paymentAction.message} customStyles={'mt-2'} />}
      </div>

      <section>
        <Dropdown heading='Select currency'
          menuOptions={currencyOptions}
          name='currency'
          isInputActive={isInputActive} />

        {isInputActive &&
          <div className='flex flex-1 justify-center'>

            <button onClick={() => {
              resetField('currency')
              setIsInputActive(false)
            }}
              type='button'
              className='group bg-slate-50 rounded-md py-1 px-1 w-full h-4/6'>
              <p className='group-hover:text-ni-red-dark group-hover:font-semibold d-inline '>
                back to select currency
              </p>
            </button>
          </div>
        }
        {errors?.currency && <InputsError errorText={errors?.currency?.message} customStyles={'mt-2'} />}
        {/* temporally condition to let the user know that the entered currency code will not work  */}
        {currentCurrency && !errors?.currency &&
          !['AED', 'USD', 'EUR', 'EGP', 'JOD', 'GBP', 'SAR'].includes(currentCurrency.toUpperCase())
          && <InputsError errorText={'This currency is not available'} customStyles={'mt-2'} />}
      </section>

      <TextInput
        pattern={/^[A-Za-z]+$/}
        name="email"
        heading="Email Address"
        placeholder="Enter email address"
      />
      {errors?.email && <InputsError errorText={errors.email.message} customStyles={'mt-2'} />}

      {/* <TextInput
        addedClass="d-none"
        name="redirectUrl"
        heading="Redirect URL"
        placeholder="Enter redirect url"
      /> */}
      {/* {errors.redirectUrl && <p className="text-danger">{errors.redirectUrl.message}</p>} */}


      <div className='flex justify-start items-center'>
        <input
          {...register('skip3DS')}
          className="h-4 w-4 shrink-0 accent-ni-red"
          type="checkbox"
          id="default-checkbox"
          label="Skip 3ds"
        />
        <span id="terms-description"
          className="p-2 text-sm text-gray-100">Skip 3ds</span>
      </div>
      {errors?.skip3DS && <InputsError errorText={errors.skip3DS.message} customStyles={'mt-2'} />}



      <div className='flex justify-start items-center'>
        <input
          {...register('maskPaymentInfo')}
          className="h-4 w-4 shrink-0 accent-ni-red"
          type="checkbox"
          id="default-checkbox"
          label="Skip 3ds"
        />
        <span id="terms-description"
          className="p-2 text-sm text-gray-100">Mask Payment Info</span>
      </div>
      {errors.maskPaymentInfo && <p className="text-rose-700 d-flex flex-nowrap w-100">
        {errors.maskPaymentInfo.message}
      </p>}
      {errors?.maskPaymentInfo && <InputsError errorText={errors.maskPaymentInfo.message} customStyles={'mt-2'} />}


      <div className='flex justify-start items-center'>
        <input
          {...register('skipFraudCheck')}
          className="h-4 w-4 shrink-0 accent-ni-red"
          type="checkbox"
          id="default-checkbox"
          label="Skip 3ds"
        />
        <span id="terms-description"
          className="p-2 text-sm text-gray-100">Skip Fraud Check</span>
      </div>
      {errors.skipFraudCheck && <InputsError errorText={errors?.skipFraudCheck?.message} customStyles={'mt-2'} />}

      <div className='flex flex-col items-start gap-2 pt-2'>
        <p className='text-gray-100 text-lg inline my-auto'>
          Skip Risk Rules
        </p>
        <RadioOptions
          additionalStyles={'flex flex-wrap'}
          options={['ALL', 'BASIC', 'ADVANCED', 'None']}
          name="skipRiskRules"
          isRequired={false} />

        {errors?.skipRiskRules && <InputsError errorText={errors?.skipRiskRules?.message} customStyles={'mt-2'} />}
      </div>

    </Fragment>);
}

export default PaymentAction;
