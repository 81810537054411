// React
import React, {useEffect, useRef, useState} from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
// Props type
import PropTypes from 'prop-types';

// React hook form
import { useForm, FormProvider } from 'react-hook-form';

// Components
import { EnvSection } from './env-section/env-section';
import Spinner from '../Spinner/Spinner';
import PaymentAction from './paymentAction/PaymentAction';
import KsaPaymentAction from './ksaPaymentAction/KsaPaymentAction';

// action creators
import { fetchEnvsData } from '../../redux/environmentsReducer/actions';

// Style
import './merchant-configurations.style.css';

// Action creator
import { updatePaymentRequestData } from '../../redux/paymentRequestReducer/actions';

// Router dom
import { useNavigate } from 'react-router-dom';
import { isKsaRegion } from '../../utils';
import {createOrder} from '../../redux/createOrderReducer/actions';
import authInstance from '../../axios/authInstance';
import {toast} from 'react-toastify';
import {toastTheme} from '../../utils/toastTheme';

const MerchantConfigurations = ({ setShowNotify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken, refreshToken } = useSelector((state) => state.authReducer);
  const { allEnvs, loading } = useSelector((state) => state.environmentsReducer);
  const { cartItems } = useSelector((state) => state.cartReducer);
  const paymentData = useSelector((state) => state.paymentRequestReducer);
  const shouldProcessPaymentForKsa = useRef(false);
  const { paymentLink, createOrderLoading } = useSelector((state) => state.createOrderReducer);
  const [unscheduledPaymentLoading, setUnscheduledPaymentLoading] = useState(false);
  const { handleSubmit, ...methods } = useForm();

  const onSubmit = (data) => {
    // store the payment data in redux to future needs
    dispatch(updatePaymentRequestData(data));

    // need to check if item added to cart to show error or not
    if (!isCartValid(cartItems, data)) {
      window.scrollTo(0, 0)
      return setShowNotify(true);
    } else {
      setShowNotify(false);
    }
    if (!isKsaRegion()) {
      navigate('/payment', { replace: true })
    } else {
      shouldProcessPaymentForKsa.current = true;
    }
  };

  useEffect(() => {
    if (paymentLink) { window.location.href = paymentLink; }
  }, [paymentLink]);

  useEffect(  () => {
    if (isKsaRegion() && shouldProcessPaymentForKsa.current && paymentData.order.parentOrderReference) {
      doUnscheduledPayment();
      shouldProcessPaymentForKsa.current = false;
      return;
    }
    if (isKsaRegion() && shouldProcessPaymentForKsa.current) {
      dispatch(createOrder(paymentData, accessToken, refreshToken));
    }
  }, [paymentData, shouldProcessPaymentForKsa]);

  useEffect(() => {
    dispatch(fetchEnvsData(accessToken, refreshToken));
  }, [refreshToken]);

  function isCartValid(cartItems, data) {
    const isCartEmpty = cartItems?.length === 0;
    return !isCartEmpty || (isKsaRegion() && (
        data.paymentAction === 'VERIFY' ||
        data.unscheduledType === 'SUBSEQUENT' ||
        Number.parseInt(data.customAmount) > 0)
    );
  }

  const doUnscheduledPayment = async () => {
    setUnscheduledPaymentLoading(true);
    try {
      await authInstance.post('payment/unscheduled', {
        envId: paymentData.envId,
        merchantId: paymentData.merchantId,
        parentOrderReference: paymentData.order.parentOrderReference,
        tenantName: paymentData.tenantName,
        outletReference: paymentData.outletReference
      });
      toast.success('Subsequent unscheduled payment has been placed with the parent order type and amount', toastTheme);
    } catch (err) {
      const errorMessage = err.response?.data?.message ?? 'Something went wrong';
      toast.error(errorMessage, toastTheme);
    } finally {
      setUnscheduledPaymentLoading(false);
    }
  };

  if (loading || createOrderLoading || unscheduledPaymentLoading) return <Spinner />;

  return (
    <div className="x-auto max-w-2xl px-6 lg:max-w-7xl lg:px-6 py-12">
      <p className="text-xl w-full font-semibold py-2 mb-4 text-gray-200 uppercase">Merchant Configurations</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <EnvSection envOptions={allEnvs?.map((env) => env.name)}/>
          {isKsaRegion() ? <KsaPaymentAction/> : <PaymentAction/>}
          <button
            className="flex justify-between items-center cursor-pointer rounded-md bg-ni-red
              px-4 py-1 text-white transition group w-full mt-4 mb-10 md:mb-0 lg:mb-0
              md:justify-around lg:justify-around">
            <span className=" rounded py-1 font-bold">
              Proceed to payment
            </span>
            <svg className="ml-4 h-6 w-6 transition-all group-hover:ml-8
               transform translate-y-0 translate-x-3" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </button>
        </FormProvider>
      </form>
    </div>
  );
};

export default MerchantConfigurations;

MerchantConfigurations.propTypes = {
  setShowNotify: PropTypes.func
};

