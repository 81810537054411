// Constants
import * as PRODUCTS_CONSTANTS from './constants'


// Currency exchange rates
import { currenciesExchangeRates } from '../../utils/currencyExchangeRates'


export const updateProductsCurrency = (newCurrency) => (dispatch, getState) => {

    const { products, currentCurrency } = getState().productsReducer;

    if (!(newCurrency in currenciesExchangeRates) || newCurrency === currentCurrency) {
        return
    }

    const updatedProducts = products?.map((product) => (
        { ...product, amount: convertCurrency(product?.amount, currentCurrency, newCurrency) }
    ))

    dispatch({ type: PRODUCTS_CONSTANTS.UPDATE_PRODUCTS_CURRENCY, payload: { updatedProducts, newCurrency } })
}



const convertCurrency = (amount, fromCurrency, toCurrency) => {
    const conversionRate = currenciesExchangeRates[fromCurrency][toCurrency];
    const convertedAmount = amount * conversionRate;

    return convertedAmount;
}