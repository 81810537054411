// React
import React from 'react';

// Components
import { DotLoader } from 'react-spinners';

const Spinner = ({ customStyles }) => (
  <div data-testid='wrapper' className={`flex justify-center items-center flex-col gap-12
   h-[calc(100vh-theme('spacing.16'))] ${customStyles}`}>
    <DotLoader
      color="#F26363"
      loading
      size={100}
      aria-label="Loading"
      data-testid="loader"
    />
    <h3 className='text-[#F26363]'>Loading...</h3>
  </div>
);

export default Spinner;
