// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Components
import MerchantConfigurations from '../../components/merchant-configurations/merchant-configurations';
import { Catalogue } from '../../components/catalogue/Catalogue';
import Spinner from '../../components/Spinner/Spinner';
// import Modal from '../../components/Modal/Modal';


// Styles
import './style.css';

// Router dom
import { useSearchParams } from 'react-router-dom';

// action creator
import { getOrderDetails } from '../../redux/orderDetailsReducer/action';
import { restCreatePaymentData } from '../../redux/createPaymentReducer/actions';
import { resetPaymentRequestData } from '../../redux/paymentRequestReducer/actions';


export const CreateOrder = ({ isConfig, setModal }) => {
  const { order, ...rest } = useSelector((state) => state.paymentRequestReducer);
  const { createOrderLoading } = useSelector((state) => state.createOrderReducer);
  const { challengeLoading } = useSelector((state) => state.challengeReducer);

  const [searchParams] = useSearchParams();
  const orderReference = searchParams.get('ref');
  
  const dispatch = useDispatch();
  const [showNotify, setShowNotify] = useState(false);

  const orderDetailsBody = { orderReference, ...rest }
  const { orderData } = useSelector((state) => state.orderDetailsReducer);

  useEffect(() => {
    dispatch(resetPaymentRequestData());
    dispatch(restCreatePaymentData());
  }, [])


  useEffect(() => {
    if (orderReference !== null) {
      dispatch(getOrderDetails(orderDetailsBody))
    }
  }, [orderReference])


  useEffect(() => {
    if (orderData.orderStatus) {
      setModal({ show: true, data: orderData.orderStatus, orderRef: orderReference })
    }
  }, [orderData])

  if (challengeLoading || createOrderLoading) return <Spinner />;

  return (
    <div>
      <div className="flex w-full relative">
        <div className={`flex-1 md:w-2/3 lg:w-2/3 ${isConfig ? 'h-screen overflow-hidden' : ''}`}>
          <Catalogue showNotify={showNotify} />
        </div>

        <div className={`merchant-parent bg-gray-800 flex-2 md:w-1/3 lg:w-1/4 h-screen overflow-scroll
         md:relative md:h-auto fixed top-[18px] md:top-0 lg:top-0
          right-0 lg:relative lg:h-auto ${isConfig ? 'block w-screen slide' : 'lg:block md:block hidden'}`}>
          <MerchantConfigurations setShowNotify={setShowNotify} />
        </div>

      </div>
    </div>
  );
};

