// Router dom
import { Link, useParams } from 'react-router-dom';

// Components
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import SettlementButton from '../../../settlement/SettlementButton';

// React
import React, { useState } from 'react';

const Breadcrumbs = () => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { environment, tenant, merchant, envId, tenId, merchantId } = useParams();
    const crumbs = [];
    const [currentCrumb, setCurrentCrumb] = useState({
        type: '',
        id: null
    });

    if (tenant) {
        crumbs.push({ label: environment, path: `/merchantConfigurations/${environment}/${envId}` });
        crumbs.push({ label: tenant, path: `/merchantConfigurations/${environment}/${envId}/${tenant}/${tenId}` });

        if (merchant) {
            crumbs.push({
                label: merchant,
                path: `/merchantConfigurations/${environment}/${envId}/${tenant}/${tenId}/${merchant}/${merchantId}`
            });
        }
    }

    const handleDelete = () => {
        setShowConfirmModal(true)
        // made the delete button dynamic depending on the params to know which component is rendered now
        if (merchant) {
            return setCurrentCrumb({ type: 'merchants', id: merchantId })
        } else {
            return setCurrentCrumb({ type: 'tenants', id: tenId })
        }
    }

    return (
        <>
            <ConfirmationModal info={currentCrumb}
                showModal={showConfirmModal} close={() => setShowConfirmModal(false)} />
            <div className='flex justify-center gap-8 flex-wrap w-full lg:justify-between md:justify-between'>
                <div className='flex flex-row gap-2'>
                    {tenant &&
                        crumbs?.map((crumb, index) => (
                            <div key={crumb?.label} className='flex justify-center items-center'>
                                {index !== 0 && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="#d42f49"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 18l6-6-6-6" />
                                </svg>
                                }
                                <Link className={`no-underline cursor-pointer text-xs md:text-base lg:text-base
                             ${index === crumbs?.length - 1 ? 'text-gray-600 font-semibold' : 'text-gray-600'} `}
                                    to={crumb?.path}>{crumb?.label}</Link>
                            </div>
                        ))
                    }
                </div>

                <div className='flex gap-2'>
                    <button
                        onClick={() => handleDelete()}
                        className='border-2 border-rose-500 text-red-500 text-xs md:text-base lg:text-base
                hover:bg-rose-500 hover:text-white px-8 py-2 rounded-md font-medium'>
                        Delete {merchant ? 'Merchant' : 'Tenant'}
                    </button>
                    <SettlementButton />
                </div>
            </div>
        </>
    );
};

export default Breadcrumbs;
