// axios instances
import generalInstance from '../../axios/generalInstance';
import authInstance from '../../axios/authInstance';

// Constants
import * as AUTH_CONSTANTS from './constants';

// Action creator
import { resetPaymentRequestData } from '../paymentRequestReducer/actions';

// eslint-disable-next-line consistent-return
export const handleUserLogin = (credentials) => async (dispatch) => {
  dispatch({ type: AUTH_CONSTANTS.LOADING_START });

  try {
    const response = await generalInstance.post('', { username: credentials.username, password: credentials.password });
    if (response.status === 200 && response.data.accessToken) {
      dispatch({
        type: AUTH_CONSTANTS.AUTH_SUCCESS,
        payload: { accessToken: response.data.accessToken, refreshToken: response.data.refreshToken }
      });
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return true;
    }
  } catch (error) {
    const errorMessage = 'You have entered an invalid username or password';
    if (error.response.status === 423) {
      dispatch({type: AUTH_CONSTANTS.AUTH_FAILED, payload: error.response?.data?.message ?? errorMessage});
    } else {
      dispatch({ type: AUTH_CONSTANTS.AUTH_FAILED, payload: errorMessage });
    }
    return false;
  }
};

export const checkAuthToken = () => (dispatch) => {
  // TODO: just need to update the this function to check on specific flag not the key in the local storage -- back-end
  dispatch({ type: AUTH_CONSTANTS.LOADING_START });

  const accessToken = localStorage.getItem('accessToken');
  if (accessToken && accessToken.includes('eyJhbGciOiJIUzUxMiJ9')) {
    dispatch({ type: AUTH_CONSTANTS.CHECK_AUTH });
    return true;
  }
  return dispatch({ type: AUTH_CONSTANTS.AUTH_FAILED });
};


export const LogoutUser = () => (dispatch) => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    authInstance.post('auth/logout', { refreshToken }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      dispatch({ type: AUTH_CONSTANTS.LOGOUT_USER });
      dispatch(resetPaymentRequestData());
    })
  } catch (error) {
    dispatch({ type: AUTH_CONSTANTS.AUTH_ERROR, payload: error })
  }
};


export const refreshTokenHandler = (refreshToken) => (dispatch) => {
  try {
    authInstance.post('auth/refreshToken', { refreshToken }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      dispatch({ type: AUTH_CONSTANTS.HANDLE_REFRESH_TOKEN, payload: response.data });
    })
  } catch (error) {
    dispatch({ type: AUTH_CONSTANTS.AUTH_FAILED });
  }
};


export const checkIfAdmin = () => (dispatch) => {
  dispatch({ type: AUTH_CONSTANTS.ADMIN_LOADING_START });

  // TEMPORARY SOLUTION UNTIL FIGURE OUT ANOTHER WAY FROM THE BACK-END
  authInstance.get('/auth/user')
    .then((resp) => {

      if (resp.data?.roles[0] === 'ADMIN') {
        dispatch({ type: AUTH_CONSTANTS.IS_ADMIN, payload: true });
      } else {
        dispatch({ type: AUTH_CONSTANTS.IS_ADMIN, payload: false })
      }
    }).catch(() => {
      dispatch({ type: AUTH_CONSTANTS.IS_ADMIN, payload: false })
    })
}


export const updateAccessAndRefreshTokens = (accessToken, refreshToken) => (dispatch) => {
  dispatch({ type: AUTH_CONSTANTS.UPDATE, payload: { accessToken, refreshToken } });
}
