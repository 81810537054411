// React
import React from 'react';

// Props type
import PropTypes from 'prop-types';

// hook form
import { useFormContext } from 'react-hook-form';

// styles
import './style.css';

const getValidationOptions = (inputName) => {
  switch (inputName) {
    case 'currency':
      return {
        required: { value: true, message: `Please enter a valid ${inputName}` },
        pattern: { value: /^[A-Za-z]{3}$/, message: 'Invalid currency code' }
      };
    case 'email':
      return {
        pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, message: 'Invalid email address' }
      };
    case 'redirectUrl':
      return {
        // required: { value: true, message: 'URL field is required' },
        pattern: { value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, message: 'Invalid URL' }
      };
    case 'passengerNameRecord':
      return {
        pattern: { value: /^[A-Za-z0-9]*$/, message: 'Invalid PNR' }
      }
    case 'numberOfInstallmentTenure':
      return {
        required: { value: true, message: 'Number of Tenure field is required' },
        pattern: { value: /^([2-9]|[1-9]\d+)$/, message: 'Invalid tenure for installments' }
    }
    case 'customAmount':
      return {
        pattern: { value: /^\d+$/, message: 'The amount is invalid' }
    }
    case 'parentOrderReference':
      return {
        required: { value: true, message: 'Parent Order Reference field is required' },
        pattern: {
          value: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
          message: 'Invalid parent order reference'
        }
    }
    case 'savedCardOrderReference':
      return {
        required: { value: true, message: 'Order Reference field is required' },
        pattern: {
          value: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
          message: 'Invalid order reference'
        }
    }
    default:
      return {};
  }
};


export const TextInput = ({ heading, placeholder, disabled, name, addedClass = 'd-block' }) => {
  const { register } = useFormContext();

  return (
    <div className={`text-input ${addedClass} pt-2`}>
      <label htmlFor="heading" className="mb-2 block text-sm font-medium text-gray-100 capitalize">{heading}</label>
      <input
        {...register(name, getValidationOptions(name))}
        className="block w-full rounded-md border border-gray-200 py-4 px-2 pr-11
        text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
        type="text"
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

TextInput.propTypes = {
  heading: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  addedClass: PropTypes.string
};

TextInput.defaultProps = {
  heading: '',
  disabled: false
};
