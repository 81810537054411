// React
import React, { Fragment, useEffect, useState } from 'react';

// Router dom
import { Routes, Route, Navigate, useNavigate, useSearchParams, Outlet } from 'react-router-dom';

// React redux
import { useDispatch, useSelector } from 'react-redux';

// Components
import { CreateOrder } from './pages/create-order/createOrder';
import { LoginPage } from './pages/login/loginPage';
import { Header } from './components/header/header';
import Auth from './components/auth';
import Spinner from './components/Spinner/Spinner';
import Modal from './components/Modal/Modal';

// Components / Admin
import { Merchants } from './components/admin/MerchantConfig/merchants/Merchants';
import { Outlets } from './components/admin/MerchantConfig/Outlets/Outlets';
import Admin from './components/admin/auth/index';

// fonts
import './fonts/Gotham/GothamBold.ttf';
import './fonts/Gotham/GothamMedium.ttf';

// Action creator
import { checkAuthToken, checkIfAdmin } from './redux/authReducer/actions';
import { resetOrderDetails } from './redux/orderDetailsReducer/action';
import { resetChallengeData } from './redux/challengeReducer/actions';
import { restCreatePaymentData } from './redux/createPaymentReducer/actions';

// Styles
import './app.css';

// Pages
import { LandingPage } from './pages/landing/landingPage';
import { Payment } from './pages/payment/Payment';
import { About } from './pages/about/about';

// Admin Pages
import { MerchantConfig } from './pages/admin/merchantConfig/merchantConfig';
import { ManageUsers } from './pages/admin/manageUsers/manageUsers';

// React Toastify
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, accessToken, refreshToken } = useSelector((state) => state.authReducer);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useState({ show: false, data: null, orderRef: '' });

  useEffect(() => {
    if (!dispatch(checkAuthToken())) {
      navigate('/login', { replace: true });
    }
    dispatch(checkIfAdmin(accessToken, refreshToken));
  }, [refreshToken]);

  const handleCloseModal = () => {
    searchParams.delete('showStatus')
    searchParams.delete('ref')
    setSearchParams(searchParams);
    localStorage.removeItem('persist:root');
    setModal({ show: false, data: null, orderRef: '' });
    dispatch(resetOrderDetails());
    dispatch(resetChallengeData());
    dispatch(restCreatePaymentData());
  }

  if (loading) return <Spinner />

  return (
    <Fragment>
      <ToastContainer />
      <Header toggle={() => {
        setIsConfigOpen(!isConfigOpen)
      }} />

      {modal.show && <Modal closeModal={handleCloseModal} data={modal.data} orderRef={modal?.orderRef} />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/products" element={<Auth><CreateOrder isConfig={isConfigOpen} setModal={setModal} /></Auth>} />
        <Route path="/payment" element={<Auth><Payment setModal={setModal} /></Auth>} />
        <Route path="/merchantConfigurations" element={<Auth><Admin><Outlet /></Admin></Auth>} >
          <Route path=':environment/:envId/:tenant/:tenId/:merchant/:merchantId' element={<Outlets />} />
          <Route path=':environment/:envId/:tenant/:tenId' element={<Merchants />} />
          <Route path=':environment/:envId?' element={<MerchantConfig />} />
        </Route>
        <Route path="/manageUsers" element={<Auth><Admin><ManageUsers /></Admin></Auth>} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Fragment >
  );
};

export default App;
