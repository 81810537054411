import { useEffect, useState } from 'react';

const usePasswordMatch = (password, confirmPassword) => {
    const [passwordMatch, setPasswordMatch] = useState(true);

    useEffect(() => {
        setPasswordMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    return passwordMatch;
};

export default usePasswordMatch;
