// React
import React from 'react'

// Redux
import { useSelector } from 'react-redux';

function OrderSummary() {
    const { cartItems } = useSelector((state) => state.cartReducer);
    const { order } = useSelector((state) => state.paymentRequestReducer);
    const totalPrice = cartItems?.reduce((acc, item) => acc + (item.amount * item.quantity), 0);


    return (
        <div className="bg-gray-800 col-span-full lg:col-span-4
        relative flex flex-col min-h-[calc(100vh-theme('spacing.16'))] py-6 px-4">
            <h2 className="relative text-2xl font-medium pb-4 text-neutral-200">Order summary</h2>
            <ul className="space-y-5 flex flex-col justify-between items-stretch p-0">
                {cartItems?.map((item) => (
                    <li className="flex justify-between items-center">
                        <div className="inline-flex gap-x-3 items-center">
                            <img src={item.image} alt={item.name} className="max-h-16 rounded-full" />
                            <div className="ml-3">
                                <p className="text-base font-medium text-neutral-200">{item.name}</p>
                            </div>
                        </div>
                        <p className="text-lg font-medium text-neutral-200">{item.totalPrice}</p>
                    </li>
                ))}
            </ul>
            <div className="my-12 h-0.5 w-full bg-neutral-200 text-neutral-200" />
            <div className="space-y-2">
                <p className="flex justify-between text-lg font-bold text-neutral-200">
                    <span>Total price:</span>
                    <span>{`${order.amount.currencyCode} ${Number(totalPrice).toFixed(0)} `}</span>
                </p>
            </div>
        </div>);
}

export default OrderSummary;