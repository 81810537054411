import * as CHALLENGE_CONSTANTS from './constants';

const initialState = {
    challengeResponse: {},
    challengeLoading: false,
    challengeError: null
}


const challengeReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHALLENGE_CONSTANTS.CHALLENGE_CALL_SUCCESS:
            return { ...state, challengeResponse: action.payload, challengeLoading: false };
        case CHALLENGE_CONSTANTS.CHALLENGE_CALL_LOADING:
            return { ...state, challengeLoading: true }
        case CHALLENGE_CONSTANTS.CHALLENGE_CALL_ERROR:
            return { ...state, challengeError: action.payload, challengeLoading: false };
        case CHALLENGE_CONSTANTS.RESET_CHALLENGE_DATA:
            return initialState
        default:
            return state
    }
}

export default challengeReducer;