// React
import React, { useState } from 'react'

// Components
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Modal from '../../Modal/Modal';
import InputsError from '../../../InputsError/InputsError';

// Axios instance
import authInstance from '../../../../axios/authInstance';

// React hook form
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// React toastify
import { toast } from 'react-toastify';
import { toastTheme } from '../../../../utils/toastTheme';

// Custom hook
import usePasswordMatch from '../hook/usePasswordMatch';

const UsersTable = ({ users, refetchUsers, loading }) => {

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState({ show: false, username: '' });
    const [deleteUserId, setDeleteUserId] = useState('');

    const { accessToken } = useSelector((state) => state.authReducer);

    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const passwordMatch = usePasswordMatch(watch('password'), watch('confirmPassword'));

    const handleResetPassword = (data) => {

        if (!passwordMatch) return;

        loading(true)
        authInstance.post('admin/auth/resetPassword', {
            username: showResetModal.username,
            password: data.password
        },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then(() => {
                loading(false)
                toast.success('Password updated successfully', toastTheme)
            })
            .catch(() => {
                loading(false)
                toast.error('Something went wrong', toastTheme)
            })
            .finally(() => {
                setShowResetModal({ show: false, username: '' });
            })
    }

    return (
        <>
            <ConfirmationModal info={{ type: 'users', id: deleteUserId }}
                showModal={showConfirmModal} close={() => setShowConfirmModal(false)}
                refetchItems={refetchUsers} />

            <Modal showModal={showResetModal.show} title={'Reset User Password'}>
                {({ controls: Controls }) =>
                    <form onSubmit={handleSubmit(handleResetPassword)}>
                        <div className='pt-4 flex flex-col gap-4'>
                            <div>
                                <label htmlFor="username" className="block text-sm font-medium
                                         text-gray-900">User Name :</label>
                                <input
                                    value={showResetModal?.username}
                                    type="text" id="username"
                                    className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                                focus:border-red-500 disabled hover:cursor-not-allowed"/>
                                {errors?.username &&
                                    <InputsError errorText={'This field is required'} customStyles={'m-0'} />}
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium
                                         text-gray-900">Password :</label>
                                <input
                                    {...register('password', { required: true })}
                                    type="password" id="password"
                                    className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                                focus:border-red-500"/>
                                {errors?.password &&
                                    <InputsError errorText={'This field is required'} customStyles={'m-0'} />}
                            </div>

                            <div>
                                <label htmlFor="confirmPassword" className="block text-sm font-medium
                                     text-gray-900">Confirm Password :</label>
                                <input
                                    {...register('confirmPassword', { required: true })}
                                    type="password" id="confirmPassword"
                                    className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                                {(errors?.confirmPassword) ?
                                    <InputsError errorText={'This field is required'} customStyles={'m-0'} />
                                    :
                                    (!passwordMatch && !errors.confirmPassword) &&
                                    <InputsError errorText={'⚠ passwords do not match'} customStyles={'m-0'} />
                                }
                            </div>
                        </div>
                        <Controls onClose={() => setShowResetModal({ show: false, username: '' })}
                            btnText={'Reset Password'} />
                    </form>
                }
            </Modal>

            <div className="overflow-x-auto w-auto rounded-lg border-2
               border-gray-500 h-fit max-h-96 overflow-y-auto">
                <table className="w-full divide-y-2 divide-gray-200 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right">
                        <tr>
                            <th className="whitespace-nowrap px-4 py-2 font-bold text-gray-800">#</th>
                            <th className="whitespace-nowrap px-4 py-2 font-bold text-gray-800">Name</th>
                            <th className="whitespace-nowrap px-4 py-2 font-bold text-gray-800">Role</th>
                            <th className="whitespace-nowrap text-center py-2 font-bold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200">
                        {users?.map((user, index) => (
                            <tr key={index}>
                                <td className="whitespace-nowrap 
                            px-4 py-2 font-medium text-gray-900">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap 
                        px-4 py-2 font-medium text-gray-900">
                                    {user?.username}
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                                    {user?.role}
                                </td>
                                <td className="whitespace-nowrap text-center py-2">
                                    <div className="flex justify-center items-center gap-2">
                                        <button
                                            onClick={() => setShowResetModal({ show: true, username: user?.username })}
                                            className="bg-ni-blue hover:bg-blue-900 hover:text-white
                                                 w-auto p-2 rounded-md text-gray-50 text-xs md:text-sm lg-text-base ">
                                            Reset Password
                                        </button>
                                        <button onClick={() => {
                                            setDeleteUserId(user?.id)
                                            setShowConfirmModal(true)
                                        }}
                                            className="bg-gray-50 border-2 border-ni-red w-1/3 p-2 rounded-md
                                     text-ni-red hover:bg-ni-red hover:text-gray-50 text-xs md:text-sm lg-text-base">
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UsersTable