import React from 'react'

const ShowModalButton = ({ type, show }) => {
    return (
        <div className='flex justify-center items-center mt-2'>
            <button onClick={() => show()}
                className='focus:outline-none text-white bg-green-700 hover:bg-green-800
                 focus:ring-4 focus:ring-green-600 
                rounded-lg text-sm px-12 py-2.5 mb-2 dark:bg-green-600 font-bold dark:hover:bg-green-700
                 dark:focus:ring-green-800' type='submit'>Add New {type}</button>
        </div>
    )
}

export default ShowModalButton