import { combineReducers } from 'redux';

// Reducers
import authReducer from '../authReducer/authReducer';
import paymentRequestReducer from '../paymentRequestReducer/paymentRequestReducer';
import environmentsReducer from '../environmentsReducer/environmentsReducer';
import createOrderReducer from '../createOrderReducer/createOrderReducer';
import orderDetailsReducer from '../orderDetailsReducer/orderDetailsReducer';
import productsReducer from '../productsReducer/productsReducer';
import createPaymentReducer from '../createPaymentReducer/createPaymentReducer';
import cartReducer from '../cartReducer/cartReducer';
import  challengeReducer  from '../challengeReducer/challengeReducer';

export default combineReducers({
  authReducer,
  paymentRequestReducer,
  environmentsReducer,
  createOrderReducer,
  orderDetailsReducer,
  productsReducer,
  createPaymentReducer,
  challengeReducer,
  cartReducer
});
