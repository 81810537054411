// Constants
import * as ENVS_CONSTANTS from './constants';

// axios auth instance
import authInstance from '../../axios/authInstance';


export const fetchEnvsData = () => (dispatch) => {
  dispatch({ type: ENVS_CONSTANTS.FETCH_ENVS_START });

  try {
    authInstance.get('/config/envs', {
    }).then(response => dispatch({ type: ENVS_CONSTANTS.FETCH_ENVS_SUCCESS, payload: response.data }))
      .catch((error) => {
        dispatch({ type: ENVS_CONSTANTS.FETCH_ENVS_ERROR, payload: error });
      });
  } catch (error) {
    dispatch({ type: ENVS_CONSTANTS.FETCH_ENVS_ERROR, payload: error });
  }
};

export const updateSelectedTenant = (selectedTenant) => (dispatch) => {
  dispatch({ type: ENVS_CONSTANTS.UPDATE_SELECTED_TENANT, payload: selectedTenant });
};


export const updateCurrentEnv = (envId) => (dispatch) => {
  dispatch({ type: ENVS_CONSTANTS.UPDATE_CURRENT_ENV, payload: envId })
}
