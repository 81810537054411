import React from 'react'

const InputsError = ({ errorText, customStyles }) => {
    return (
        <div className="w-100">
            <p className={`text-rose-700 ${customStyles}`}>
                {errorText}
            </p>
        </div>
    )
}

export default InputsError