import * as ENVS_CONSTANTS from './constants';

const initialState = {
  allEnvs: [],
  currentEnv: '',
  selectedTenant: {},
  loading: false,
  error: null
};


const environmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENVS_CONSTANTS.FETCH_ENVS_START:
      return { ...state, loading: true };

    case ENVS_CONSTANTS.FETCH_ENVS_SUCCESS:
      return { ...state, loading: false, allEnvs: action.payload };

    case ENVS_CONSTANTS.FETCH_ENVS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ENVS_CONSTANTS.UPDATE_SELECTED_TENANT:
      return { ...state, selectedTenant: action.payload };

    case ENVS_CONSTANTS.UPDATE_CURRENT_ENV:
      return { ...state, currentEnv: action.payload }
    default:
      return state;
  }
};

export default environmentsReducer;
