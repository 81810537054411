// Router dom
import { useParams } from 'react-router-dom';

// React
import React, { useEffect, useState } from 'react';

// Hook form
import { useForm } from 'react-hook-form';

// Auth instance
import authInstance from '../../../../axios/authInstance';

// Components
import Layout from '../../../../pages/admin/merchantConfig/layout';
import Spinner from '../../../Spinner/Spinner';
import Modal from '../../Modal/Modal';
import ItemsList from '../itemsList/ItemsList';
import NoDataFound from '../../NoDataFound/NoDataFound';
import ShowModalButton from '../../showModalButton/ShowModalButton';

// React Taosify
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { toastTheme } from '../../../../utils/toastTheme';


export const Outlets = () => {
  const params = useParams();
  const { merchant, merchantId } = params;

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [, setCreateError] = useState(null);
  const [currentMerchantOutlets, setCurrentMerchantOutlets] = useState({
    outlets: [],
    loading: false,
    error: null
  });

  useEffect(() => {
    fetchMerchantOutlets();
  }, [merchant])

  const fetchMerchantOutlets = () => {
    setCurrentMerchantOutlets({ ...currentMerchantOutlets, loading: true });

    authInstance.get(`admin/config/merchants/${merchantId}/outlets`)
      .then((response) =>
        setCurrentMerchantOutlets({ ...currentMerchantOutlets, outlets: response.data, loading: false }))
      .catch((error) => setCurrentMerchantOutlets({ ...currentMerchantOutlets, error: error, loading: false }));
  }


  function onSubmit(data) {
    authInstance.post(`admin/config/merchants/${merchantId}/outlets`, {
      name: data.outletName,
      reference: data.outletRef
    })
      .then(() => {
        toast.success('Outlet created successfully', toastTheme)
      })
      .catch((error) => {
        toast.error('Something went wrong', toastTheme)
        setCreateError(error)
      })
      .finally(() => {
        setShowModal(false)
        fetchMerchantOutlets();
        reset();
      })
  }


  if (currentMerchantOutlets?.loading) return <Spinner />

  return (
    <Layout>

      <div className='flex justify-center items-center'>
        <p className='text-center text-sm md:text-xl mb-4 lg:text-xl font-[600] text-gray-800'>
          Outlets Of {merchant}
        </p>
      </div>

      <Modal showModal={showModal} title={'Create New Outlet'} >
        {({ controls: Controls }) => (
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className='pt-4 flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <label htmlFor="outletName" className="block text-sm font-medium text-gray-900">
                  Outlet Name :
                </label>
                <input
                  {...register('outletName', { required: true })}
                  type="text" id="outletName"
                  className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                  focus:border-red-500"/>
                {errors?.outletName && <p className='text-red-500 mb-0 block font-normal m-0'>
                  This field is required
                </p>}

                <label htmlFor="outletRef" className="block text-sm font-medium text-gray-900">
                  Outlet Reference :
                </label>
                <input
                  {...register('outletRef', { required: true })}
                  type="text" id="outletRef"
                  className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                  focus:border-red-500"/>
                {errors?.outletRef && <p className='text-red-500 mb-0 block font-normal m-0'>
                  This field is required
                </p>}
              </div>
              <Controls btnText={'Create Outlet'} onClose={() => setShowModal(false)} />
            </div>
          </form>
        )}
      </Modal>

      {/* Rendering the outlets */}
      <>
        {currentMerchantOutlets.outlets.length !== 0 ?
          <div className='flex flex-col gap-3 h-3/4 overflow-y-auto'>
            {currentMerchantOutlets?.outlets?.map((singleOutlet) => (
              <div key={singleOutlet?.reference} className='w-full group'>
                <ItemsList item={singleOutlet} type={'outlet'} refetchOutlets={fetchMerchantOutlets} />
              </div>
            ))}
          </div>
          :
          <NoDataFound sectionName={'Outlets'} />
        }
      </>

      <ShowModalButton type={'Outlet'} show={() => setShowModal(true)} />
    </Layout>
  )
}