// Router dom
import { Navigate, useNavigate } from 'react-router-dom';

// React
import React, { useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// action creators
import { handleUserLogin } from '../../redux/authReducer/actions';

// Styles
import './style.css';
import LandingPageImage from '../../images/LandingPage.jpg'

// React hook form
import { useForm } from 'react-hook-form';

// Components
import InputsError from '../../components/InputsError/InputsError'

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, loginFailed } = useSelector((state) => state.authReducer);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  if (isAuth) {
    return <Navigate to="/products" />;
  }

  const onSubmit = (data) => {
    setIsLoggedIn(dispatch(handleUserLogin(data)));
    if (isLoggedIn) {
      navigate('/products');
    }
  }

  return (
    <div className="flex w-full flex-wrap h-[calc(100vh-theme('spacing.16'))] overflow-hidden">
      <div className="flex w-full flex-col md:w-1/2 lg:w-1/3">
        <div
          className="my-auto flex flex-col justify-center px-6 pb-16 sm:px-24 md:justify-start md:px-8 md:pt-0 lg:px-12"
        >
          <p className="text-center text-4xl font-bold text-ni-blue">Welcome</p>
          <p className="mt-2 text-center text-sm text-ni-blue">Login to access your account.</p>
          <form className="flex gap-3 flex-col pt-3 md:pt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="relative flex flex-col overflow-hidden rounded-lg border
             focus-within:border-transparent focus-within:ring-2 transition focus-within:ring-ni-blue">
              <input
                {...register('username', { required: true })}
                type="text"
                id="username"
                className="w-full flex-1 appearance-none border-ni-blue bg-white
              py-2 px-4 text-base text-gray-700 placeholder-gray-400  focus:outline-none"
                placeholder="Username"
              />

            </div>
            {errors.username && <InputsError errorText={'This field is required'} />}
            <div
              className="relative flex overflow-hidden rounded-lg border focus-within:border-transparent
                focus-within:ring-2 transition focus-within:ring-ni-blue"
            >
              <input
                {...register('password', { required: true })}
                type="password"
                id="password"
                className="w-full flex-1 appearance-none border-ni-blue bg-white py-2 px-4 text-base
                  text-gray-700 placeholder-gray-400  focus:outline-none"
                placeholder="Password"
              />
            </div>
            {errors.password && <InputsError errorText={'This field is required'} />}
            {loginFailed && <p className="text-rose-700">{loginFailed}</p>}

            <button
              type="submit"
              className="w-full rounded-lg bg-ni-red px-4 py-2 text-center text-base font-semibold
              text-white shadow-md transition ease-in hover:bg-ni-red-dark focus:outline-none focus:ring-2"
            >
              <span className="w-full"> Login </span>
            </button>
          </form>
        </div>
      </div>
      <div className="pointer-events-none hidden select-none md:block md:w-1/2 lg:w-2/3">
        <img
          className="h-screen w-full object-cover opacity-90"
          src={LandingPageImage}
          alt=""
        />
      </div>
    </div>
  );
};

