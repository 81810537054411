// React
import React, { useEffect, useMemo, useState } from 'react'

// SVG
import warning from '../../../svg/warning.svg'

// Axios instance 
import authInstance from '../../../axios/authInstance'

// Router dom
import { useNavigate, useParams } from 'react-router-dom'

// Components
import Spinner from '../../Spinner/Spinner'

// React toastify
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { toastTheme } from '../../../utils/toastTheme'

const ConfirmationModal = ({ showModal, close, info, refetchItems }) => {
    const navigate = useNavigate();
    const [deleteAction, setDeleteAction] = useState({
        loading: false,
        error: null
    })
    const [deleteUrl, setDeleteUrl] = useState('');

    const params = useParams();
    const { environment, envId, tenant, tenId, merchant, merchantId } = params;

    const memoizedInfo = useMemo(() => info, [info]);

    useEffect(() => {
        if (memoizedInfo.type === 'outlets') {
            setDeleteUrl(`admin/config/merchants/${merchantId}/outlets/${memoizedInfo.reference}`)
        } else if (memoizedInfo.type === 'users') {
            setDeleteUrl(`admin/auth/users/${memoizedInfo?.id}`)
        }
        else {
            // this url will be fit to delete all tenants, merchants
            setDeleteUrl(`admin/config/${memoizedInfo?.type}/${memoizedInfo?.id}`)
        }
    }, [memoizedInfo])



    const handleConfirmDeletion = () => {

        setDeleteAction({ ...deleteAction, loading: true });

        authInstance.delete(deleteUrl)
            .then(() => {
                setDeleteAction({ ...deleteAction, loading: false });
                switch (info?.type) {
                    case 'merchants':
                        toast.success('merchant deleted successfully', toastTheme);
                        return navigate(`/merchantConfigurations/${environment}/${envId}/${tenant}/${tenId}`,
                            { replace: true })
                    case 'tenants':
                        toast.success('tenant deleted successfully', toastTheme);
                        return navigate(`/merchantConfigurations/${environment}/${envId}`, { replace: true })
                    case 'outlets':
                        toast.success('Outlet deleted successfully', toastTheme);
                        // eslint-disable-next-line max-len
                        return navigate(`/merchantConfigurations/${environment}/${envId}/${tenant}/${tenId}/${merchant}/${merchantId}`, { replace: true })
                    case 'users':
                        toast.success('user deleted successfully', toastTheme);
                        return navigate('/manageUsers')
                    default:
                        return
                }

            })
            .catch((err) => {
                let errorMessage = err.response?.data?.message ?? 'Something went wrong';
                toast.error(errorMessage, toastTheme);
                setDeleteAction({...deleteAction, loading: false, error: err});
            })
            .finally(() => {
                if (refetchItems) {
                    // delete outlet and user require a refetch for outlets and users data
                    // because the user stays at the same route 
                    // force update
                    refetchItems();
                }
                close();
            })
    }

    if (deleteAction.loading) return <Spinner />
    return (
        <>
            {showModal ?
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0
                        z-50 outline-none focus:outline-none bg-opacity-100 ">
                        <div className="relative w-auto flex justify-center my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg relative flex flex-col w-2/3 bg-white
                             outline-none focus:outline-none p-4 items-center gap-2">
                                <img className='w-1/3' src={warning} alt='warning' loading='lazy' />
                                {/*header*/}
                                <div className="flex flex-col items-center justify-center p-3 border-b border-solid
                                 border-blueGray-200 rounded-t">
                                    <h5 className="text-gray-800 font-semibold">
                                        Are you sure ?
                                    </h5>
                                    <h6 className='text-center'>
                                        Once deleted, you will not be able to recover these data
                                    </h6>
                                </div>
                                <div className='w-full flex justify-around items-center'>
                                    <button onClick={() => close()}
                                        className='bg-gray-300 px-3 py-2 rounded'>Cancel</button>
                                    <button onClick={() => handleConfirmDeletion()}
                                        className='bg-red-500 px-3 py-2 rounded text-gray-50'>Yes, Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
                :
                null
            }
        </>
    )
}

export default ConfirmationModal