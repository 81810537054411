// React
import React, { useEffect, useLayoutEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Router dom
import { useLocation, useNavigate } from 'react-router-dom';

const Admin = ({ children }) => {

  const { isAdmin, adminLoading } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (isAdmin && location.pathname.includes('merchantConfigurations')) {
      navigate('/merchantConfigurations/DEV/102')
    }
  }, [])

  useEffect(() => {
    if (!isAdmin && !adminLoading) {
      navigate('/products')
    }
  }, [isAdmin, adminLoading, navigate])


  if (adminLoading) return null

  return <>
    {children}
  </>
};

export default Admin;