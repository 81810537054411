export const LOADING_START = 'LOADING_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CHECK_AUTH = 'CHECK_AUTH';
export const UPDATE = 'UPDATE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const HANDLE_REFRESH_TOKEN = 'HANDLE_REFRESH_TOKEN';
export const IS_ADMIN = 'IS_ADMIN';
export const ADMIN_LOADING_START = 'ADMIN_LOADING_START';
