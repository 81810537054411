import * as CREATE_ORDER_CONSTANTS from './constants';

// Auth instance
import authInstance from '../../axios/authInstance';

// React toastify
import { toast } from 'react-toastify'
import { toastTheme } from '../../utils/toastTheme'
import { isKsaRegion } from '../../utils';

export const createOrder = (paymentData) => (dispatch) => {
  dispatch({ type: CREATE_ORDER_CONSTANTS.FETCH_START_LOADING });
  try {
    authInstance.post('/order',
      paymentData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        dispatch({ type: CREATE_ORDER_CONSTANTS.FETCH_SUCCESS, payload: response.data });
        return true;
      })
      .catch((error) => {
        if(isKsaRegion() && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message, toastTheme)
        } else {
          toast.error('Something went wrong', toastTheme)
        }
        dispatch({ type: CREATE_ORDER_CONSTANTS.FETCH_ERROR, payload: error });
      });
  } catch (error) {
    dispatch({ type: CREATE_ORDER_CONSTANTS.FETCH_ERROR, payload: error });
  }
};
