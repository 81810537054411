import React, {useEffect} from 'react';

// eslint-disable-next-line import/extensions
import { useForm } from 'react-hook-form';

// styles
import './style.css';

import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';


// action creator
import { createOrder } from '../../redux/createOrderReducer/actions';
import { createPaymentAndOrder } from '../../redux/createPaymentReducer/actions';

// Components 
import Spinner from '../Spinner/Spinner'
import InputsError from '../InputsError/InputsError';

const PaymentForm = ({ setModal }) => {
  const { accessToken, refreshToken } = useSelector((state) => state.authReducer);
  const paymentData = useSelector((state) => state.paymentRequestReducer);
  const { paymentLink, createOrderLoading } = useSelector((state) => state.createOrderReducer);
  const { createPaymentRes, createPaymentLoading } = useSelector((state) => state.createPaymentReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    if (paymentLink) { window.location.href = paymentLink; }
  }, [paymentLink]);

  useEffect(() => {
    if (createPaymentRes.state && createPaymentRes?.state !== 'AWAIT_3DS') {
      // The payment is done and the status of it is ready to show
      setModal({ show: true, data: createPaymentRes.state, orderRef: createPaymentRes.orderReference })
      navigate('/products', { replace: true });
    }
  }, [createPaymentRes]);

  const onsubmit = async (data) => {
    const fullPaymentData = {
      ...paymentData, payment:
        { ...data, expiry: `20${data.expiry.year}-${data.expiry.month}` }
    }
    dispatch(createPaymentAndOrder(fullPaymentData))
  }


  const handleNgeniusPay = () => {
    dispatch(createOrder(paymentData, accessToken, refreshToken))
  }

  if (createPaymentLoading || createOrderLoading) return <Spinner />

  return (
    <>
      <div className="col-span-full py-28 px-4 sm:py-12 lg:col-span-8 lg:py-4">
        <div className="flex flex-col items-center justify-center mx-auto w-full max-w-lg gap-6">
          <div className='w-full flex justify-start'>
            <h1 className="relative text-2xl font-bold text-neutral-800 sm:text-3xl">Secure Checkout
              <span className="mt-2 block h-1 w-10 bg-gray-800 sm:w-20" />
            </h1>
          </div>
          <form noValidate onSubmit={handleSubmit(onsubmit)} className="mt-1 flex flex-col gap-2 space-y-4">
            <div className="relative">
              <label htmlFor="card-number" className="text-xs font-semibold text-gray-800">Card Number</label>
              <input
                {...register('pan', {
                  required: { value: true, message: 'Card Number is required' },
                  pattern: { value: /^\d{14,19}$/, message: 'Card number must be between 14 to 19 digits' }
                })}
                className="block w-full rounded-md border border-gray-200 py-4 px-4 pr-11
                  text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
                placeholder="1234-5678-XXXX-XXXX"
                type="text"
              />
              {errors?.pan && <InputsError errorText={errors?.pan?.message} customStyles={'m-0'} />}
            </div>

            <div>
              <div className="grid grid-cols-8 gap-2">
                <div className="col-span-4 lg:col-span-2">
                  <label htmlFor="card-number" className="text-xs font-semibold text-gray-800">Month</label>

                  <input
                    {...register('expiry.month', {
                      required: { value: true, message: 'Month is required' },
                      pattern: { value: /^\d{2}$/, message: 'Enter valid month' }
                    })}
                    type="text"
                    // name="month"
                    placeholder="MM"
                    className="block w-full rounded-md border border-gray-200 py-4 px-4 pr-11
                      text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
                  />
                  {errors?.expiry?.month &&
                    <InputsError errorText={errors?.expiry?.month?.message} customStyles={'m-0'} />}
                </div>
                <div className="col-span-4 lg:col-span-2">
                  <label htmlFor="card-number" className="text-xs font-semibold text-gray-800">Year</label>

                  <input
                    {...register('expiry.year', {
                      required: { value: true, message: 'Year is required' },
                      pattern: { value: /^\d{2}$/, message: 'Enter valid year' }
                    })}
                    type="text"
                    placeholder="YY"
                    className="block w-full rounded-md border border-gray-200 py-4 px-4 pr-11
                      text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
                  />
                  {errors?.expiry?.year &&
                    <InputsError errorText={errors?.expiry?.year?.message} customStyles={'m-0'} />}
                </div>
                <div className="col-span-8 lg:col-span-4">
                  <label htmlFor="card-number" className="text-xs font-semibold text-gray-800">CVV</label>

                  <input
                    {...register('cvv', {
                      required: { value: true, message: 'Security Code is required' },
                      pattern: { value: /^\d{3,4}$/, message: 'Security Code must be 3 or 4 digits' }
                    })}
                    type="text"
                    id="security-code"
                    placeholder="Security code"
                    className="block w-full rounded-md border border-gray-200 py-4 px-4 pr-11
                      text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
                  />
                  {errors?.cvv && <InputsError errorText={errors?.cvv?.message} customStyles={'m-0'} />}
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="card-number" className="text-xs font-semibold text-gray-800">Name on card</label>

              <input
                {...register('cardholderName', {
                  required: { value: true, message: 'Name on card is required' },
                  pattern: { value: /^[A-Za-z\s.'-]+$/, message: 'Invalid name' }
                })}
                type="text"
                id="card-name"
                placeholder="Name on the card"
                className="block w-full rounded-md border border-gray-200 py-4 px-4 pr-11
                  text-sm shadow-sm outline-none focus:z-10 focus:border-gray-800 focus:ring-gray-800"
              />
              {errors?.cardholderName &&
                <InputsError errorText={errors?.cardholderName?.message} customStyles={'m-0'} />}
            </div>

            <p className="mt-10 text-center text-sm font-semibold text-gray-500">By placing this order you agree to
              the <a href="#" className="whitespace-nowrap text-gray-500 underline hover:text-gray-800">Terms and
                Conditions
              </a>
            </p>
            <button
              type="submit"
              className="mt-4 inline-flex w-full items-center justify-center rounded bg-gray-800 py-2.5 px-4
                    text-base font-semibold tracking-wide text-white text-opacity-100 outline-none ring-offset-2
                    transition hover:text-opacity-90 focus:ring-2 focus:ring-gray-500 sm:text-lg"
            >
              Pay Now
            </button>
          </form>

          <button
            onClick={handleNgeniusPay}
            type='button'
            className="items-center w-full justify-center rounded bg-ni-red py-2.5 px-4
                    text-base font-semibold tracking-wide text-white text-opacity-100 outline-none ring-offset-2
                    transition hover:text-opacity-90 focus:ring-2 focus:ring-gray-500 sm:text-lg"
          >
            Pay On ngenius Paypage
          </button>
        </div>
      </div>
    </>

  );
}

export default PaymentForm;
