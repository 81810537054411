// Router dom
import { Link, Outlet, useParams } from 'react-router-dom';

// React 
import React, { useEffect, useState } from 'react';

// Components
import Layout from '../../../../pages/admin/merchantConfig/layout';
import Spinner from '../../../Spinner/Spinner';
import Modal from '../../Modal/Modal';
import ShowModalButton from '../../showModalButton/ShowModalButton';
import ItemsList from '../itemsList/ItemsList';
import NoDataFound from '../../NoDataFound/NoDataFound';

// Auth instance
import authInstance from '../../../../axios/authInstance';

// Hook form
import { useForm } from 'react-hook-form';

// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { toastTheme } from '../../../../utils/toastTheme';



export const Merchants = () => {
    const params = useParams();
    const { tenant, tenId } = params;
    const [showModal, setShowModal] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [, setCreateError] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [currentTenantMerchants, setCurrentTenantMerchants] = useState({
        merchants: [],
        loading: false,
        error: null
    });

    useEffect(() => {

        setCurrentTenantMerchants({ ...currentTenantMerchants, loading: true });

        authInstance.get(`admin/config/tenants/${tenId}/merchants`)
            .then((response) =>
                setCurrentTenantMerchants({ ...currentTenantMerchants, merchants: response.data, loading: false }))
            .catch((error) => setCurrentTenantMerchants({ ...currentTenantMerchants, error: error, loading: false }));
    }, [tenant, refetch])


    function onSubmit(data) {

        authInstance.post('admin/config/merchants', {
            tenantId: tenId,
            name: data?.merchantName,
            apiKey: data?.merchantApiKey
        })
            .then(() => {
                toast.success('Merchant created successfully', toastTheme)
            })
            .catch((error) => {
                toast.error('Something went wrong', toastTheme)
                setCreateError(error)
            })
            .finally(() => {
                setShowModal(false)
                setRefetch(!refetch)
                reset();
            })
    }

    if (currentTenantMerchants.loading) return <Spinner />

    return (
        <Layout>

            <div className='flex justify-center items-center'>
                <p className='text-center text-sm md:text-xl mb-4 lg:text-xl font-[600] text-gray-800'>
                    Merchants Of {tenant}
                </p>
            </div>

            <Modal showModal={showModal} title={'Create New Merchant'}  >
                {({ controls: Controls }) => <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='pt-4 flex flex-col gap-4'>
                        <div>
                            <label htmlFor="merchantName" className="block text-sm font-medium
                                     text-gray-900">Merchant Name :</label>
                            <input
                                {...register('merchantName', { required: true })}
                                type="text" id="merchantName"
                                className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                            {errors?.merchantName && <p className='text-red-500 mb-0 block font-normal'>
                                This field is required
                            </p>}
                        </div>

                        <div>
                            <label htmlFor="merchantApiKey" className="block text-sm font-medium
                                     text-gray-900">Merchant Api Key :</label>
                            <input
                                {...register('merchantApiKey', { required: true })}
                                type="text" id="merchantApiKey"
                                className="bg-gray-50 border text-black text-sm rounded-lg p-2.5 w-full
                            focus:border-red-500"/>
                            {errors?.merchantApiKey && <p className='text-red-500 mb-0 block font-normal'>
                                This field is required
                            </p>}
                        </div>
                    </div>

                    <Controls onClose={() => setShowModal(false)} btnText={'Create Merchant'} />
                </form>}
            </Modal>

            {/* Rendering the merchants */}
            <>
                {currentTenantMerchants.merchants.length !== 0 ?
                    <div className='flex flex-col gap-3 h-3/4 overflow-y-auto'>
                        {currentTenantMerchants?.merchants?.map((merchant) => (
                            <div key={merchant?.id} className='w-full group'>
                                <Link className='no-underline'
                                    key={merchant?.name} to={`${merchant?.name}/${merchant?.id}`} >
                                    <ItemsList item={merchant} type={'merchant'} />
                                </Link>
                            </div>
                        ))
                        }
                        <Outlet />
                    </div>
                    :
                    <NoDataFound sectionName={'Merchants'} />
                }
                <ShowModalButton type={'Merchant'} show={() => setShowModal(true)} />
            </>
        </Layout >
    )
}