// Constants
import * as PRODUCTS_CONSTANTS from './constants'

// Products data
import { dummyProducts } from '../../utils/dummyProducts';

import { isKsaRegion } from '../../utils/index';


const initialState = {
    products: dummyProducts,
    currentCurrency: isKsaRegion() ? 'SAR' : 'AED',
    ProductsLoading: false,
    error: null
}

const productsReducer = (state = initialState, action) => {

    switch (action.type) {

        case PRODUCTS_CONSTANTS.UPDATE_PRODUCTS_CURRENCY: {
            return { ...state, products: action.payload.updatedProducts, currentCurrency: action.payload.newCurrency }
        }
        default:
            return state
    }
}

export default productsReducer;
