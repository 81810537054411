// Store
import { legacy_createStore as createStore, applyMiddleware } from 'redux';

// Thunk
import thunk from 'redux-thunk';

// Redux-persist
import { persistStore, persistReducer } from 'redux-persist'

//Storage
import storage from 'redux-persist/lib/storage'

// all reducers
import reducers from './allReducers/reducers';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['paymentRequestReducer', 'cartReducer', 'environmentsReducer']
}

const persistedReducer = persistReducer(persistConfig, reducers)


const factory =  () => {
    let store = createStore(persistedReducer, applyMiddleware(thunk));
    // Persistor word is mandatory as is !!
    let persistor = persistStore(store)
    return { store, persistor }
  }

const {store, persistor} = factory();

export {store, persistor}



