const ModalSvg = ({ status }) => {
    return (
        <>
            {status === 'FAILED' ?
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="aspect-square w-10 md:w-16 lg:w-20 rounded-xl bg-green-50 p-2 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
                :
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="aspect-square w-16 md:w-20 lg:w-20 rounded-xl bg-green-50 p-2 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                    />
                </svg>
            }
        </>

    );
}

export default ModalSvg;