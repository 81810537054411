import * as CART_CONSTANTS from './constants';

export const addToCart = (item) => (dispatch) => {
    dispatch({ type: CART_CONSTANTS.ADD_TO_CART, payload: item })
}

export const addQuantity = (item) => (dispatch) => {
    dispatch({ type: CART_CONSTANTS.ADD_QUANTITY, payload: item })
}

export const removeItem = (item) => (dispatch) => {
    dispatch({ type: CART_CONSTANTS.REMOVE_FROM_CART, payload: item })
}

export const subQuantity = (item) => (dispatch) => {
    dispatch({ type: CART_CONSTANTS.SUB_QUANTITY, payload: item })
}

export const emptyCart = () => (dispatch) => {
    dispatch({ type: CART_CONSTANTS.EMPTY_CART })
}
