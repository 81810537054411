import * as PAYMENT_CONSTANTS from './constants';

// action creator
import { updateCurrentEnv } from '../environmentsReducer/actions';

// Utils Functions
import { getMinorUnit } from '../../utils';

export const updateEnv = (newValue, allEnvs) => (dispatch) => {
  const currentEnv = allEnvs.find((env) => env.name === newValue);
  dispatch({ type: PAYMENT_CONSTANTS.UPDATE_ENV, payload: currentEnv?.id });
  dispatch(updateCurrentEnv(currentEnv?.id))
};

export const updateTotal = (newValue) => (dispatch) => {
  dispatch({ type: PAYMENT_CONSTANTS.UPDATE_TOTAL, payload: newValue });
};

export const updatePaymentRequestData = (paymentData) => (dispatch, getState) => {
  const { order } = getState().paymentRequestReducer;
  const currencyMinorUnit = getMinorUnit(paymentData.currency);
  const minorUnitValue = parseFloat((order.amount.value * (10 ** currencyMinorUnit).toFixed(currencyMinorUnit)))

  // update the total value again to get it's minor unit depending on the currency code.
  // because the tow values are coming form two deferent components 
  dispatch(updateTotal(minorUnitValue));
  dispatch({ type: PAYMENT_CONSTANTS.UPDATE_PAYMENT_REQUEST_DATA, payload: paymentData });
};

export const resetPaymentRequestData = () => (dispatch) => {
  dispatch({ type: PAYMENT_CONSTANTS.RESET_PAYMENT_REQUEST_DATA});
};

