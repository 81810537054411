// React
import React from 'react';

// SVG
import noData from '../../../svg/noData.svg';

const NoDataFound = ({ sectionName }) => {
    return (
        <div className='flex flex-col border border-red-200
                     justify-center items-center gap-3 h-3/4' >
            <img className='w-20 aspect-square' src={noData} alt='noData' loading='lazy' />
            <h5 className='font-bold text-sm md:text-base lg:text-base
             text-[#cacaca]'>There is no {sectionName} to show </h5>
        </div>
    )
}

export default NoDataFound