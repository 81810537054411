import * as ORDER_DETAILS_CONSTANTS from './constants';

const initialState = {
    orderData: {},
    loading: false,
    error: null
}


const orderDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_DETAILS_CONSTANTS.FETCH_LOADING_START:
            return { ...state, loading: true };
        case ORDER_DETAILS_CONSTANTS.FETCH_SUCCESS:
            return { ...state, orderData: action.payload.data, loading: false };
        case ORDER_DETAILS_CONSTANTS.FETCH_FAILED:
            return { ...state, error: action.payload, loading: false }
        case ORDER_DETAILS_CONSTANTS.EMPTY_LOCAL_STORAGE:
            localStorage.removeItem('persist:root')
            return state
        case ORDER_DETAILS_CONSTANTS.RESET_DETAILS:
            return initialState;
        default:
            return state
    }
}

export default orderDetailsReducer;