// React
import React from 'react';

const DefaultControls = ({ onClose, btnText }) => {

    return <div className="flex items-center justify-evenly flex-wrap gap-2 my-4 border-t border-solid
    border-blueGray-200 rounded-b">
        <button
            className="text-gray-100 background-transparent d-block w-full
            font-bold uppercase
            py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear
             transition-all duration-150 bg-green-700 rounded-md p-6"
            type="submit">
            {btnText}
        </button>

        <button
            className="text-rose-500 background-transparent font-bold uppercase
            py-2 text-sm mr-1 mb-1 ease-linear
             transition-all duration-150 rounded-md p-6 border-2 border-rose-500"
            type="button"
            onClick={() => onClose(false)}>
            Close
        </button>
    </div>
}

const Modal = ({ children, showModal, title }) => {

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0
                         z-50 outline-none focus:outline-none bg-opacity-100">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white
                             outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-center p-3 border-b border-solid
                                 border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl text-gray-800 font-semibold">
                                        {title}
                                    </h3>
                                </div>
                                {children({ controls: DefaultControls })}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

export default Modal;
