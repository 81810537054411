// React
import React from 'react';

// React Dom
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
// import { persister, store } from './redux/store';

// Persist Gate
import { PersistGate } from 'redux-persist/integration/react'

// Styles
import './app.css';

// Components
import App from './App';

import { store, persistor } from './redux/store'



const root = ReactDOM.createRoot(document.getElementById('root'));

// Post the message of completion once the value of base64EncodedCRes is founded 
// to receive it in the 3DS component and use to call the challenge api **
var base64EncodedCRes = document.location.href.match(/base64EncodedCRes=([^&]*)/);
if (base64EncodedCRes !== null) {
  window.parent.postMessage('3DS2_CHALLENGE_COMPLETE:' + base64EncodedCRes[1], '*');
}


root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </HashRouter>
  </React.StrictMode>
);
