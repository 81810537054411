// React
import React, { useEffect } from 'react';

// Props type
import PropTypes from 'prop-types';

// Styles
import 'font-awesome/css/font-awesome.min.css';
import './style.css';

// Form context
import { useFormContext } from 'react-hook-form';
import { TextInput } from '../text-input/text-input';


export const Dropdown = ({ heading, menuOptions, isInputActive, name, disableOtherOption = false }) => {
  const { register, resetField } = useFormContext();

  useEffect(() => {
    resetField(name)
  }, [isInputActive])

  return (

    <div className="dropdown">
      {isInputActive ?
        <TextInput name={name} heading={name} placeholder={name} />
        :
        <select className="dropdown-content"
          {...register(name, {
            required: { value: true, message: `${name} is required` }, shouldUnregister: true
          })}>
          <option value="" className="dropdown-placeholder">{heading}</option>
          {menuOptions?.map((option) =>
          (
            <option value={option} key={option} className="dropdown-option">
              {option}
            </option>
          ))}
          {!disableOtherOption && <option value={'other'}>Other</option>}
        </select>
      }
    </div>
  );
}

Dropdown.propTypes = {
  heading: PropTypes.string.isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  isInputActive: PropTypes.bool,
  disableOtherOption: PropTypes.bool
};

export default Dropdown;
