import appleWatch from '../images/products/apple-watch-1.jpg';
import appleMac from '../images/products/apple-macBook-pro.jpg';
import airPods from '../images/products/air-pods.jpg';
import headphone from '../images/products/headphone.jpg';
import virtualReality from '../images/products/virtualReality.jpg';
import keyBoard from '../images/products/key-board.jpg';
import smartSpeaker from '../images/products/smart-speaker.jpg';
import smartCup from '../images/products/smart-cup.jpg';

export const dummyProducts = [
    {
        id: 1,
        name: 'Apple watch',
        image: appleWatch,
        amount: 777.00,
        totalPrice: 777.00
    },
    {
        id: 2,
        name: 'Apple MacBook Pro',
        image: appleMac,
        amount: 6250.00,
        totalPrice: 6250.00
    },
    {
        id: 3,
        name: 'air pods',
        image: airPods,
        amount: 25.00,
        totalPrice: 25.00
    },
    {
        id: 4,
        name: 'Wireless Headphone',
        image: headphone,
        amount: 260.00,
        totalPrice: 260.00
    },
    {
        id: 5,
        name: 'Virtual Reality Headset',
        image: virtualReality,
        amount: 400.00,
        totalPrice: 400.00
    },
    {
        id: 6,
        name: 'Red Dragon keyboard',
        image: keyBoard,
        amount: 120.00,
        totalPrice: 120.00
    },
    {
        id: 7,
        name: 'Smart Speaker',
        image: smartSpeaker,
        amount: 700.00,
        totalPrice: 700.00
    },
    {
        id: 8,
        name: 'Smart Cup',
        image: smartCup,
        amount: 200.00,
        totalPrice: 200.00
    }
]