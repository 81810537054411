// React
import React, { useEffect, useState } from 'react';

// Axios instance
import authInstance from '../../../axios/authInstance';

// Components
import Tenants from '../../../components/admin/MerchantConfig/Tenants/Tenants';
import Layout from './layout';
import Spinner from '../../../components/Spinner/Spinner';

// Router dom
import { useParams } from 'react-router-dom';

export const MerchantConfig = () => {


    const params = useParams();
    const { environment, envId } = params;

    const [refetch, setRefetch] = useState(false);
    const [currentEnvTenants, setCurrentEnvTenants] = useState({
        tenants: [],
        loading: false,
        error: null
    });

    useEffect(() => {
        setCurrentEnvTenants({ ...currentEnvTenants, loading: true });

        authInstance.get(`admin/config/envs/${envId}/tenants`)
            .then((response) =>
                setCurrentEnvTenants({ ...currentEnvTenants, tenants: response.data, loading: false }))
            .catch((error) => setCurrentEnvTenants({ ...currentEnvTenants, error: error, loading: false }))
    }, [environment, refetch])




    if (currentEnvTenants.loading) return <Spinner />

    return (
        <Layout>
            <Tenants tenants={currentEnvTenants?.tenants} refetchTenants={() => setRefetch(!refetch)} />
        </Layout>
    );
}